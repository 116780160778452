import React, { Fragment } from 'react';
import './Bingo.css';
import '../../App.css';
import { GetImgUrl, LOG_ENABLE, gotoFirstPage, gotoTalkPage, handleAppNavi, mobileOn, withNavigation } from '../../URL/AppUrl';
import {  GetDataDateAndTime, GetFormattedCNum2, getShortedPathName, IsValidS, IsValidV, } from '../../Util/Util';
import Loading from '../Loading/Loading';
import FooterBar from '../Footer/Footer';
import BingoCard from './BingoCard';


//빙고 주문하기 1단계
class BingoOrder1 extends React.Component
{
  state = {
    loading: false,

    bingoType: 0, //일반형(0), 집중형(1)
    gameType: 5, //일반형스타터(10000원), 일반형베이직(90000원), 일반형디럭스(450000원), 일반형프리미엄(1000000원)
  };

  setLoading = (v) =>
  {
    this.setState((prevState) => ({...prevState, loading : v}));
  }

  setBingoType = (v) =>
  {
    this.setState((prevState) => ({...prevState, bingoType : v}));

    this.setGameType(v === 0 ? 5 : 1);
  }

  setGameType = (v) =>
  {
    this.setState((prevState) => ({...prevState, gameType : v}));
  }

  //사이징----------------------------------------------------------------------------------------------
  
  componentDidMount() {
    this.setBingoType(this.props.bingoType);
    this.setGameType(this.props.gameType);
  }

  componentWillUnmount = () =>
  {
  }

  getCurrentTitle = () =>
  {
    if (this.state.bingoType === 0)
      return "일반형 빙고 광고 특징";
    else
      return "집중형 빙고 광고 특징";
  }

  getBingoDesc = () =>
  {
    let bingoInfo = [];

    if (this.state.bingoType === 0)
    {
      bingoInfo.push(<span key={1}>게이트 배너와 게임 상단 배너가 노출되며, 머니팡 유저에게 5회 게임 플레이를 제공합니다.</span>);
      bingoInfo.push(<br key={2}/>);
      bingoInfo.push(<span key={3}>상품 이미지를 전면 노출하여 강력한 브랜딩 효과를 제공하며, 많은 머니팡 유저에게 브랜드를 알릴 수 있습니다.</span>);
      bingoInfo.push(<br key={4}/>);
      bingoInfo.push(<span key={5}>일반형 빙고 광고는 광고 효율을 중시하는 광고주에게 적합한 광고 유형입니다.</span>);
    }
    else
    {
      bingoInfo.push(<span key={6}>게이트 배너와 게임 상단 배너가 노출되며, 머니팡 유저에게 25회 게임 플레이와 광고 쇼츠 2회 시청을 제공합니다.</span>);
      bingoInfo.push(<br key={7}/>);
      bingoInfo.push(<span key={8}>다양한 상품과 키워드를 포함한 9개의 심볼 이미지를 제작하여 브랜드 메시지를 유저에게 집중적으로 각인시킬 수 있습니다.</span>);
      bingoInfo.push(<br key={9}/>);
      bingoInfo.push(<span key={10}>집중형 빙고 광고는 상품과 서비스의 각인 효과 및 전환율을 높이는 데 효과적인 광고 유형입니다.</span>);
    }

    return bingoInfo;
  }

  handleSelectGame = (gameNum) =>
  {
    this.setGameType(gameNum);
  }

  handleStartGame = (gameNum) =>
  {
    this.setGameType(gameNum);

    if (this.state.bingoType !== 0 && this.state.bingoType !== 1)
    {
      alert(`빙고팡팡 광고 상품을 선택해 주세요.`);
      return;
    }

    this.props.handleNextStep(0, {bingoType: this.state.bingoType, gameType: gameNum});
  }

  render()
  {
    return (
      <div className='bingocont colel' style={{width: this.props.contWidth, height: this.props.height}}>
        <div className='bingocont2 coltl' style={{width: this.props.contWidth - 40 - 50, height: this.props.height - 100}}>
          <label className='font20 wt600 color31 mgtop20'>빙고팡팡 광고</label>

          <div className='wd100p rowlc mgtop25'>
            <button className={this.state.bingoType === 0 ? 'bingobtn3 font16 wt500 color20 btncur mgrt10' : 'bingobtn4 font16 wt400 color31 btncur mgrt10'} onClick={() => this.setBingoType(0)}>일반형 빙고 광고</button>
            <button className={this.state.bingoType === 1 ? 'bingobtn3 font16 wt500 color20 btncur' : 'bingobtn4 font16 wt400 color31 btncur'} onClick={() => this.setBingoType(1)}>집중형 빙고 광고</button>
          </div>
          
          <div className='bingodescbox1 colcl mgtop15'>
            <label className='font15 wt600 color31 mglt15'>{this.getCurrentTitle()}</label>
            <label className='font15 wt400 color31 mglt15 mgtop5'>{this.getBingoDesc()}</label>
          </div>

          {
            this.state.bingoType === 0 ? (
              <div className='bingotooltip mgtop20 rowcl' style={{backgroundImage: `url("${GetImgUrl("bingo/tooltip.png")}")`}}>
                <label className='font16 wt500 colorff36 mgtop2'>이 상품은 최초 1회만 구매 가능합니다.</label>
              </div>
            ) : null
          }          

          {
            this.state.bingoType === 0 ? (
              <div className='wd100p rowlc mgtop15 mgbt50'>
                <BingoCard key={1} selected={this.state.gameType} index={5} price={10000} title="일반형 스타터 빙고" uc={800} tc={4000} cpm={1130} bingoType={0} handleSelectGame={this.handleSelectGame} handleStartGame={this.handleStartGame}/>
                <BingoCard key={2} selected={this.state.gameType} index={4} price={90000} title="일반형 베이직 빙고" uc={6000} tc={30000} cpm={1350} bingoType={0} handleSelectGame={this.handleSelectGame} handleStartGame={this.handleStartGame}/>
                <BingoCard key={3} selected={this.state.gameType} index={3} price={450000} title="일반형 디럭스 빙고" uc={36000} tc={180000} cpm={1130} bingoType={0} handleSelectGame={this.handleSelectGame} handleStartGame={this.handleStartGame}/>
                <BingoCard key={4} selected={this.state.gameType} index={2} price={1000000} title="일반형 프리미엄 빙고" uc={90000} tc={450000} cpm={1000} bingoType={0} handleSelectGame={this.handleSelectGame} handleStartGame={this.handleStartGame}/>
              </div>
            ) : (
              <div className='wd100p rowlc mgtop20 mgbt50'>
                {/*<BingoCard key={5} selected={this.state.gameType} index={6} price={1000000} title="집중형 프리미엄 빙고" uc={16000} tc={400000} bingoType={0} handleSelectGame={this.handleSelectGame} handleStartGame={this.handleStartGame}/>*/}
                <BingoCard key={6} selected={this.state.gameType} index={1} price={1000000} title="집중형 프리미엄 빙고" uc={16000} tc={400000} cpm={1225} bingoType={1} handleSelectGame={this.handleSelectGame} handleStartGame={this.handleStartGame}/>
              </div>
            )
          }
          
        </div>
        <FooterBar handleMenu={this.handleMenu}/>
      </div>
    );
  }
}

export default BingoOrder1;