
import React from 'react';
import {GetApiUrl, LOG_ENABLE} from '../URL/AppUrl';
import axios from 'axios';
import { FromBase64, GetSerialDateToDate, GetSerialDateToDate2, IsValidS, IsValidV, ToBase64 } from '../Util/Util';
import { LoginStoreInstance } from './LoginStore';

//포인트 주문 처리
class PointOrder {
  //observable변수

  constructor() {
  }

  clearAll = () =>
  {
    
  }
  //-------------------------------------------------------------------------------------------------------
  //주문 목록을 조회함
  //pos : 읽을 위치
  loadOrders = (pos, callback) => {

    const params = new URLSearchParams();

    params.append("rt", "0");
    params.append("pos", pos.toString());

    axios({
      method:"POST",
      url: GetApiUrl('sorder/list/'),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{
        this.parseOrderListResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseOrderListResult(null, callback);
    });
  }

  parseOrderListResult = (result, callback) => 
  {
    if (LOG_ENABLE)
      console.log(`parseOrderListResult ${result ? JSON.stringify(result) : 'unknown'}`);

    if (!IsValidV(result))
    {
      if (LOG_ENABLE)
        console.log("request failed.");

      if (callback)
      {
        callback(-2, null, null, null);
      }

      return;
    }
    
    if (callback)
    {
      if (IsValidS(result.list))
      {
        result.list.forEach(element => {
          this.decodeOrderItem(element);
        });
      }

      if (result.ret === 100)
      {
        LoginStoreInstance.sessionError = true;
      }

      callback(result.ret, result.ft, result.tc, result.list);
    }
  }

  decodeOrderItem = (item) =>
  {
    if (IsValidV(item.bi))
    {
      item.bi = JSON.parse(FromBase64(item.bi));
    }

    //무통장 또는 가상계좌 입금일때, 입금기한이 지났는지 확인하기

    //무통장/가상계좌가 아니거나, 결제 대기 상태가 아니면
    if ((item.pmd !== 0 && item.pmd !== 2) || item.st !== 1)
      return;

    let orderDate = GetSerialDateToDate2(item.rd, 23, 59, 59); //주문일자

    orderDate.setDate(orderDate.getDate() + 1);

    let nowDate = new Date();

    //일자가 경과 했다면, 임의로 주문 상태를 취소상태로 변경한다.
    if (nowDate > orderDate)
    {
      item.st = 3;
    }
  }
  //-------------------------------------------------------------------------------------------------------
  //포인트 주문하기
  //isPc : pc(true), 모바일(false)
  //pm : 무통장(0, 지원안됨현재), 카드(1), 가상계좌(2)
  //prc : 주문포인트
  //un : 무통장입금자명
  //ti : 세금계산서, 없음(0), 발행(1)
  //uph : 세금계산서 발행시 담당자 연락처
  pointOrder = (isPc, pm, prc, un, ti, uph, callback) => {

    const params = new URLSearchParams();

    params.append("pf", isPc ? "0" : "1");
    params.append("pm", pm.toString());
    params.append("prc", prc.toString());

    if (IsValidS(un))
      params.append("un", ToBase64(un));

    params.append("ti", ti.toString());

    if (IsValidS(uph))
      params.append("uph", uph);

    axios({
      method:"POST",
      url: GetApiUrl('sorder/order/'),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{
        this.parseOrderResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseOrderResult(null, callback);
    });
  }

  parseOrderResult = (result, callback) => 
  {
    if (LOG_ENABLE)
      console.log(`parseOrderResult ${result ? JSON.stringify(result) : 'unknown'}`);

    if (!IsValidV(result))
    {
      if (LOG_ENABLE)
        console.log("request failed.");

      if (callback)
      {
        callback(-2, null, null, null);
      }

      return;
    }
    
    if (callback)
    {
      if (result.ret === 100)
      {
        LoginStoreInstance.sessionError = true;
      }

      callback(result.ret, result.pm, result.oidx, result.authUrl);
    }
  }
  //-------------------------------------------------------------------------------------------------------
  /*포인트 임시 주문하기: pg사 계약완료시까지 임시 계좌입금 방식 주문함
  un: 입금자명
  prc: 충전금액
  tc: 결제금액
  tax: 세금계산서 신청여부
  tph: 세금계산서 담당자 연락처
  */
  pointTempOrder = (un, prc, tc, tax, tph, callback) => {

    const params = new URLSearchParams();

    params.append("un", ToBase64(un));
    params.append("prc", prc.toString());
    params.append("tc", tc.toString());
    params.append("tax", tax.toString());

    params.append("tph", IsValidS(tph) ? tph : 'n');

    axios({
      method:"POST",
      url: GetApiUrl('sorder/order2/'),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{
        this.parseTempOrderResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseTempOrderResult(null, callback);
    });
  }

  parseTempOrderResult = (result, callback) => 
  {
    if (LOG_ENABLE)
      console.log(`parseTempOrderResult ${result ? JSON.stringify(result) : 'unknown'}`);

    if (!IsValidV(result))
    {
      if (LOG_ENABLE)
        console.log("request failed.");

      if (callback)
      {
        callback(-2);
      }

      return;
    }
    
    if (callback)
    {
      if (result.ret === 100)
      {
        LoginStoreInstance.sessionError = true;
      }

      callback(result.ret);
    }
  }
  //-------------------------------------------------------------------------------------------------------
  //특정 주문의 한건의 정보를 읽어온다.
  //oidx : 주문 고유번호
  getOrder = (oidx, callback) => {

    const params = new URLSearchParams();

    params.append("oidx", oidx.toString());

    axios({
      method:"POST",
      url: GetApiUrl('sorder/get/'),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{
        this.parseOrderGetResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseOrderGetResult(null, callback);
    });
  }

  parseOrderGetResult = (result, callback) => 
  {
    if (LOG_ENABLE)
      console.log(`parseOrderGetResult ${result ? JSON.stringify(result) : 'unknown'}`);

    if (!IsValidV(result))
    {
      if (LOG_ENABLE)
        console.log("request failed.");

      if (callback)
      {
        callback(-2, null);
      }

      return;
    }
    
    if (callback)
    {
      if (result.ret === 100)
      {
        LoginStoreInstance.sessionError = true;
      }

      if (result.oi)
        this.decodeOrderItem(result.oi);
      
      callback(result.ret, result.oi);
    }
  }
}

const PointOrderInstance = new PointOrder();
const PointOrderContext = React.createContext(PointOrderInstance);

const UsePointOrder = () => React.useContext(PointOrderContext);

export {UsePointOrder, PointOrderInstance};
