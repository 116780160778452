import React from 'react';
import './OrderList.css';
import '../../App.css';
import { GetImgUrl, LOG_ENABLE, gotoFirstPage, gotoTalkPage, handleAppNavi, mobileOn, withNavigation } from '../../URL/AppUrl';
import {  AdsTypeToText2, IsValidS, IsValidV, } from '../../Util/Util';
import Loading from '../Loading/Loading';
import TopBar from '../Top/TopBar';
import FooterBar from '../Footer/Footer';
import { LoginStoreInstance } from '../../Stores/LoginStore';
import Term from '../Term/Term';
import Privacy from '../Term/Privacy';
import Paging from '../Paging/Paging';
import Talk from '../Talk/Talk';
import OrderListItem from './OrderListItem';
import MobileMenu from '../Top/MobileMenu';
import OrderListItemMb from './OrderListItemMb';
import OrderListSel from './OrderListSel';
import OrderAs from './OrdetAs';
import { FaqStoreInstance } from '../../Stores/FaqStore';
import { AdsStoreInstance } from '../../Stores/AdsStore';
import ToastMessage from '../ToastMessage/ToastMessage';
import { resetAllUserData } from '../../Stores/StoreManager';
import Login from '../Login/Login';
import FindId from '../Login/FindId';
import FindPw from '../Login/FindPw';
import NaviBar from '../Navi/Navi';
import UserQueryDetail from '../Setting/UserQueryDetail';

class OrderList extends React.Component
{
  state = {
    loading: false,
    showTerm: false,
    showPrivacy: false,
    showMobileMenu: false,
    showAdsTypeSel: false, //광고 종류 선택창
    searchWord: '',
    searchMode: false, //검색중인가?
    showLogin: false,
    showFindId: false,
    showFindPw: false,

    showAs: false, //as문의 창 보이기
    showAsDetail: false, //as문의 결과창 보이기

    orderStatus: 0, //전체(0), 진행중(1), 종료(2)
    gender: 0, //성별, 전체(0), 남자(1), 여자(2)
    age: 0, //나이, 전체(0), 20대(1), 30대(2)
    repeat: 0, //반복여부, 전체(0), 없음(1), 있음(2)

    adsType: -1, //-1, 전체광고, 기타의 경우 플랫폼별 광고코드

    orderList:  null, //주문내역
    totalOrderCount: 0, //총주문개수

    startPage: 1, //현재의 첫페이지
    endPage: 5, //현재의 끝페이지
    lastPage: 5, //마지막 페이지번호
    pageCount: 0, //전체 페이지의 개수
    currentPage: 1, //현재 선택된 페이지

    showToastMessage: false,
    toastMessage: '',

    //사이징처리
    naviWidth: 0,
    contWidth: 0,

    height: 0,
  };

  
  intervalId = -1;
  editItem = null;
  
  currentListMode = 0; //조건없는일반목록(0), 조건1(1), 조건2(2), 조건3(3), 조건4(4), 조건5(5), 조건6(6), 조건7(7)
  currentLinkHashIdx = -1; //sns주소로 조회시에 서버에서 조회된 해시 고유번호

  //이용자의 현재 검색 조건을 확인해서 조회 타입을 결정한다.
  checkCurrentListMode = () =>
  {
    if (IsValidS(this.state.searchWord)) //검색어가 입력되어 있다면
    {
      if (this.state.adsType === -1) //전체 광고타입
      {
        if (this.state.orderStatus === 0 && this.state.gender === 0 && this.state.age === 0) //다른 검색조건이 없으면
        {
          this.currentListMode = 4; //특정 sns주소검색
        }
        else
        {
          this.currentListMode = 5; //특정 sns주소와, 주문상태, 나이, 성별검색
        }
      }
      else //특정광고타입
      {
        if (this.state.orderStatus === 0 && this.state.gender === 0 && this.state.age === 0) //다른 검색조건이 없으면
        {
          this.currentListMode = 7; //sns주소 일치하고, 광고타입일치하는
        }
        else
        {
          this.currentListMode = 6; //sns주소 일치하고, 광고타입일치, 조건일치
        }
      }
    }
    else //검색어 입력은 없다면
    {
      if (this.state.adsType === -1) //전체 광고타입
      {
        if (this.state.orderStatus === 0 && this.state.gender === 0 && this.state.age === 0) //다른 검색조건이 없으면
        {
          this.currentListMode = 0; //유저의 모든광고
        }
        else
        {
          this.currentListMode = 2; //유저의 특정 조건광고
        }
      }
      else //특정광고타입
      {
        if (this.state.orderStatus === 0 && this.state.gender === 0 && this.state.age === 0) //다른 검색조건이 없으면
        {
          this.currentListMode = 1; //유저의 특정상품광고
        }
        else
        {
          this.currentListMode = 3; //유저의 특정상품중 조건만족하는 광고
        }
      }
    }
  }
  //----------------------------------------------------------------------------------------------------
  toastMessageId = -1;

  showToastMessage = (visible, msg) =>
  {
    if (this.state.showToastMessage && this.toastMessageId !== -1)
    {
      clearTimeout(this.toastMessageId);
      this.toastMessageId = -1;
    }

    this.setState((prevState) => ({...prevState, showToastMessage: visible, toastMessage: msg}));

    if (visible)
    {
      this.toastMessageId = setTimeout(() => {
        this.setState((prevState) => ({...prevState, showToastMessage: false, toastMessage: ''}));
        clearTimeout(this.toastMessageId);
        this.toastMessageId = -1;
      }, 3000);
    }
    else if (this.toastMessageId !== -1)
    {
      clearTimeout(this.toastMessageId);
      this.toastMessageId = -1;
    }
  }
  //----------------------------------------------------------------------------------------------------
  showLogin = (v) =>
  {
    this.setState((prevState) => ({...prevState, showLogin: v}));
  }

  showFindId = (v) =>
  {
    this.setState((prevState) => ({...prevState, showFindId: v}));
  }

  showFindPw = (v) =>
  {
    this.setState((prevState) => ({...prevState, showFindPw: v}));
  }

  handleLoginEvent = (eventCode) =>
  {
    if (this.state.loading)
      return;

    switch(eventCode)
    {
      case 0: //닫기
        this.showLogin(false);
        return;
      case 1: //회원가입
        this.handleRegister();
        return;
      case 2: //id찾기
        this.showLogin(false);
        this.showFindId(true);
        return;
      case 3: //비번찾기
        this.showLogin(false);
        this.showFindPw(true);
        return;
      case 100: //로그인 성공
        this.showLogin(false);
        this.loadAds(1);
        return;
    }
  }

  handleRegister = () =>
  {
    if (this.state.loading)
      return;

    this.showLogin(false);
    this.showFindId(false);
    this.showFindPw(false);
    this.handleNavi(5);
  }

  handleFindPw = () =>
  {
    if (this.state.loading)
      return;

    this.showLogin(false);
    this.showFindId(false);
    this.showFindPw(true);
  }
  //-----------------------------------------------------------------------------------------------------------------------------
  update = () =>
  {
    if (LoginStoreInstance.sessionError && LoginStoreInstance.loggedIn)
    {
      LoginStoreInstance.sessionError = false;
      alert(`인증 세션이 만료되었습니다.`);
      this.processLogOut();
    }
  }

  getAdsTypeImg = () =>
  {
    switch(this.state.adsType)
    {
      case 0: //인스타게시물좋아요
      case 1: //인스타프로필팔로우
      case 2: //인스타 댓글
      case 3: //인스타 커스텀 댓글
        if (mobileOn)
          return <img src={GetImgUrl("list/mb/instas.png")} alt="광고" className='img1515'/>
        else
          return <img src={GetImgUrl("list/instas.png")} alt="광고" />
      
      case 10: //페이스북 게시물 좋아요
      case 11: //페이스북 팔로우
      case 12: //페이스북 댓글
      case 13: //페이스북 커스텀댓글
        if (mobileOn)
          return <img src={GetImgUrl("list/mb/facebooks.png")} alt="광고" className='img1515'/>
        else
          return <img src={GetImgUrl("list/facebooks.png")} alt="광고"/>

      case 20: //유튜브 좋아요
      case 21: //유튜브 구독자
      case 22: //유튜브 댓글
      case 23: //유튜브 커스텀 댓글
        if (mobileOn)
          return <img src={GetImgUrl("list/mb/youtubes.png")} alt="광고" className='img1511'/>
        else
          return <img src={GetImgUrl("list/youtubes.png")} alt="광고"/>

      case 30: //틱톡 좋아요
      case 31: //틱톡 팔로우
      case 32: //틱톡 댓글
      case 33: //틱톡 커스텀 댓글
        if (mobileOn)
          return <img src={GetImgUrl("list/mb/ticktocks.png")} alt="광고" className='img1515'/>
        else
          return <img src={GetImgUrl("list/ticktocks.png")} alt="광고"/>

      default:
        return null;
    }
  }

  setOrderStatus = (v) =>
  {
    this.setState((prevState) => ({...prevState, orderStatus: v}));
  }

  showAdsTypeSel = (v) =>
  {
    this.setState((prevState) => ({...prevState, showAdsTypeSel: v}));
  }

  setAdsType = (v) =>
  {
    if (this.state.loading)
      return;

    this.setState((prevState) => ({...prevState, adsType: v}));
  }

  showMobileMenu = (v) =>
  {
    this.setState((prevState) => ({...prevState, showMobileMenu: v}));
  }

  setCurrentPage = (v) =>
  {
    this.setState((prevState) => ({...prevState, currentPage: v}));
  }

  setPageInfo = (start, end, lastPage, resetCurrentPage) =>
  {
    if (resetCurrentPage)
      this.setState((prevState) => ({...prevState, startPage: start, endPage: end, lastPage: lastPage, currentPage: start}));
    else
      this.setState((prevState) => ({...prevState, startPage: start, endPage: end, lastPage: lastPage}));
  }

  setGenderMode = (v) =>
  {
    if (this.state.loading)
      return;

    this.setState((prevState) => ({...prevState, gender: v}));
  }

  setAgeMode = (v) =>
  {
    if (this.state.loading)
      return;

    this.setState((prevState) => ({...prevState, age: v}));
  }

  setRepeatMode = (v) =>
  {
    if (this.state.loading)
      return;

    this.setState((prevState) => ({...prevState, repeat: v}));
  }

  setOrderList = (v) =>
  {
    this.setState((prevState) => ({...prevState, orderList: v}));
  }

  setOrderCount = (v) =>
  {
    this.setState((prevState) => ({...prevState, totalOrderCount: v}));
  }

  setSearchWord = (v) =>
  {
    this.setState((prevState) => ({...prevState, searchWord: v}));
    this.searchHashIdx = -1;
  }

  setSearchMode = (v) =>
  {
    this.searchMode = v;
    this.setState((prevState) => ({...prevState, searchMode: v}));
  }

  showTerm = (v) =>
  {
    this.setState((prevState) => ({...prevState, showTerm: v}));
  }

  showPrivacy = (v) =>
  {
    this.setState((prevState) => ({...prevState, showPrivacy: v}));
  }

  showAsPop = (v) =>
  {
    this.setState((prevState) => ({...prevState, showAs: v}));
  }

  showAsDetail = (v) =>
  {
    this.setState((prevState) => ({...prevState, showAsDetail: v}));
  }

  handleNavi = (naviIndex) =>
  {
    if (this.state.loading)
      return;

    if (LOG_ENABLE)
      console.log(`handleNavi : ${naviIndex}`);

    //console.log(`${this.props.navigate}`);
    this.showMobileMenu(false);
    handleAppNavi(this.props.navigate, naviIndex);
  }

  handleSnsOrder = (orderType) =>
  {
    if (this.state.loading)
      return;

    if (LOG_ENABLE)
      console.log(`handleSnsOrder : ${orderType}`);

    AdsStoreInstance.setOrderType(orderType);

    handleAppNavi(this.props.navigate, 0);
  }

  handleMenu = (menuIndex) =>
  {
    if (this.state.loading)
      return;

    switch(menuIndex)
    {
      case 0: //로그인
        this.showLogin(true);
        return;
      case 6: //이용약관
        this.showTerm(true);
        return;
      case 7: //개인정보 처리방침
        this.showPrivacy(true);
        return;
      case 100: //로그아웃
        if (!window.confirm('로그아웃 할까요?'))
          return;

        this.processLogOut();
        return;
    }
  }

  processLogOut = () =>
  {
    resetAllUserData();
    gotoFirstPage();
  }

  //사이징----------------------------------------------------------------------------------------------
  setSize = (navi, cont, height) =>
  {
    this.setState((prevState) => ({...prevState, naviWidth : navi, contWidth : cont, height : height}));
  }

  resetContsize = () =>
  {
    //네비게이션 영역은 전체의 16%정도
    let naviWidth = 0.16 * window.innerWidth;

    if (naviWidth < 300) //최소크기300
      naviWidth = 300;

    //콘텐츠영역크기 계산
    let contWidth = window.innerWidth - naviWidth;

    if (contWidth < 1220)
      contWidth = 1220;

    this.setSize(naviWidth, contWidth, window.innerHeight - 70)
  }

  onResize = () => {
    this.resetContsize();
  }
  //사이징----------------------------------------------------------------------------------------------
  
  componentDidMount() {

    this.resetContsize();

    window.addEventListener("resize", this.onResize);

    this.intervalId = setInterval(this.update, 1000);

    //로그인되어 있지 않은경우, 혹시 새로고침했을수도 있다.
    if (!LoginStoreInstance.loggedIn)
    {
      if (LoginStoreInstance.loadToken()) //인증토큰 정보가 존재한다면
      {
        this.setLoading(true);
        LoginStoreInstance.loadProfile(this.onProfileLoadedForRefresh);
      }
    }
    else
    {
      if (!LoginStoreInstance.isProfileReady) //프로필이 준비되지 않았다면
      {
        this.setLoading(true);
        LoginStoreInstance.loadProfile(this.onProfileLoaded);
      }
      else
      {
        //마지막 포인트 조회 시점부터 현재까지 경과 시간이 1분이상 경과한경우
        if (LoginStoreInstance.getPassedPointTime() > 60)
        {
          LoginStoreInstance.catchPointRefreshTime();
          LoginStoreInstance.refreshUserPoint(null);
        }

        this.loadAds(1);
      }
    }
    //this.setOrderList(generateTestData());
  }

  componentWillUnmount = () =>
  {
    window.removeEventListener("resize", this.onResize);

    if (this.intervalId !== -1)
    {
      clearInterval(this.intervalId);
      this.intervalId = -1;
    }
  }

  onProfileLoadedForRefresh = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      if (LOG_ENABLE)
        console.log(`invalid profile`);

      LoginStoreInstance.removeToken();

      return;
    }

    this.loadAds(1);
  }

  onProfileLoaded = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert(`프로필 읽기 중 오류가 발생했습니다.\n다시 로그인해 주세요.`);
      this.processLogOut();
      return;
    }

    this.loadAds(1);
  }

  loadAds = (pageNumber) =>
  {
    if (!this.state.loading)
      this.setLoading(true);

    switch(this.currentListMode)
    {
      case 0: //유저의 전체광고
        AdsStoreInstance.loadAds((pageNumber - 1) * 9, this.onAdsLoaded);
        return;
      case 1: //이용자의 특정 상품 타입만 조회
        AdsStoreInstance.loadAdsCond1(this.state.adsType, (pageNumber - 1) * 9, this.onAdsLoaded);
        return;
      case 2: //이용자의 특정 조건 타입조회(주문상태, 성별, 연령)
        AdsStoreInstance.loadAdsCond2(this.state.orderStatus, this.state.gender, this.state.age, (pageNumber - 1) * 9, this.onAdsLoaded);
        return;
      case 3: //이용자의 특정 조건 타입조회(상품종류, 주문상태, 성별, 연령)
        AdsStoreInstance.loadAdsCond3(this.state.adsType, this.state.orderStatus, this.state.gender, this.state.age, (pageNumber - 1) * 9, this.onAdsLoaded);
        return;
      case 4: //이용자의 특정 주소에 해당하는 조회
        AdsStoreInstance.loadAdsCond4(this.currentLinkHashIdx, this.state.searchWord, (pageNumber - 1) * 9, this.onAdsLoaded);
        return;
      case 5: //이용자의 특정 주소와 조건에 해당하는 조회(주문상태, 성별, 연령)
        AdsStoreInstance.loadAdsCond5(this.currentLinkHashIdx, this.state.searchWord, this.state.orderStatus, this.state.gender, this.state.age, 
          (pageNumber - 1) * 9, this.onAdsLoaded);
        return;
      case 6: //이용자의 특정 주소와 조건에 해당하는 조회(상품종류, 주문상태, 성별, 연령)
        AdsStoreInstance.loadAdsCond6(this.currentLinkHashIdx, this.state.searchWord, this.state.adsType, this.state.orderStatus, this.state.gender, this.state.age, 
          (pageNumber - 1) * 9, this.onAdsLoaded);
        return;
      case 7: //이용자의 특정 주소와 상품종류가 일치하는 광고
        AdsStoreInstance.loadAdsCond7(this.currentLinkHashIdx, this.state.searchWord, this.state.adsType, (pageNumber - 1) * 9, this.onAdsLoaded);
        return;
      default:
        this.setLoading(false);
        return;
    }
    
  }

  onAdsLoaded = (resultCode, requestType, adsList, isFirst, totalCount, hashIdx) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert(`주문내역 조회 중 오류가 발생했습니다.\n잠시 후 이용해 주세요.`);
      return;
    }

    this.setOrderList(adsList);

    if (isFirst)
    {
      this.setOrderCount(totalCount);
      this.calcPageCount(totalCount);

      if (requestType > 3 && IsValidV(hashIdx))
        this.currentLinkHashIdx = hashIdx;
    }
  }

  //전체 페이지 개수를 카운트
  calcPageCount = (totalCount) =>
  {
    let pageCount = Math.floor(totalCount / 9);

    if ((totalCount % 9) !== 0)
      ++pageCount;

    let endPage = 5;

    if (endPage > pageCount)
      endPage = pageCount;

    this.setState((prevState) => ({...prevState, startPage : 1, endPage : endPage, currentPage : 1, pageCount : pageCount, lastPage: pageCount}));
  }

  setLoading = (v) =>
  {
    this.setState((prevState) => ({...prevState, loading : v}));
  }

  getModeBtn = (enabled, caption, widthMargin, index) =>
  {
    if (mobileOn)
    {
      if (enabled)
      {
        return <button className={`olbtn1_mb btncur font1420 ${widthMargin ? "marginright5" : ""}`} onClick={() => this.handleTab(index)}>{caption}</button>
      }
      else
      {
        return <button className={`olbtn2_mb btncur font14b3 ${widthMargin ? "marginright5" : ""}`} onClick={() => this.handleTab(index)}>{caption}</button>
      }
    }
    else
    {
      if (enabled)
      {
        return <button className={`olbtn1 btncur font14btn2 ${widthMargin ? "marginright5" : ""}`} onClick={() => this.handleTab(index)}>{caption}</button>
      }
      else
      {
        return <button className={`olbtn2 btncur font14btn3 ${widthMargin ? "marginright5" : ""}`} onClick={() => this.handleTab(index)}>{caption}</button>
      }
    }
  }

  handleTab = (index) =>
  {
    switch(index)
    {
      case 0: //성별 전체
        if (this.state.gender === 0)
          return;
        this.setGenderMode(0);
        break;
      case 1: //성별 남자
        if (this.state.gender === 1)
          return;
        this.setGenderMode(1);
        break;
      case 2: //성별 여자
        if (this.state.gender === 2)
          return;
        this.setGenderMode(2);
        break;
      case 3: //연령 무관
        if (this.state.age === 0)
          return;
        this.setAgeMode(0);
        break;
      case 4: //연령 20대
        if (this.state.age === 1)
          return;
        this.setAgeMode(1);
        break;
      case 5: //연령 30대
        if (this.state.age === 2)
          return;
        this.setAgeMode(2);
        break;
      case 6: //반복 미설정
        if (!this.state.repeat)
          return;
        this.setRepeatMode(false);
        break;
      case 7: //반복 설정
        if (this.state.repeat)
          return;
        this.setRepeatMode(true);
        break;
      default:
        return;
    }
  }

  handleKeyPress = (e) =>
  {
    if (LOG_ENABLE)
      console.log("handleKeyPress");

    if (e.key === "Enter")
    {
      this.handleSearch();
    }
  }

  handleSearch = () =>
  {
    if (this.state.loading || !LoginStoreInstance.loggedIn)
      return;
    
    this.checkCurrentListMode();

    if (this.currentListMode !== 0)
      this.setSearchMode(true);

    this.currentLinkHashIdx = -1; //sns주소로 조회시에 서버에서 조회된 해시 고유번호
    this.loadAds(1);
  }

  handleDoneSearch = () =>
  {
    if (this.state.loading || !LoginStoreInstance.loggedIn)
      return;

    this.setSearchMode(false);
    this.setOrderStatus(0);
    this.setAgeMode(0);
    this.setGenderMode(0);
    this.setSearchWord('');
    this.setAdsType(-1);
    this.currentListMode = 0;
    this.currentLinkHashIdx = -1; //sns주소로 조회시에 서버에서 조회된 해시 고유번호
    this.loadAds(1);
  }

  //페이지 선택처리
  handlePage = (pageNumber) =>
  {
    if (pageNumber === -1) //이전 페이지
    {
      let startPage = this.state.startPage - this.maxPageNum;
      let endPage = this.state.endPage - this.maxPageNum;

      if (startPage < 1)
        startPage = 1;
      
      if (endPage > this.state.pageCount)
        endPage = this.state.pageCount;

      this.setState((prevState) => ({...prevState, currentPage : startPage, startPage : startPage, endPage : endPage}));

      this.loadAds(startPage);
    }
    else if (pageNumber === -2) //다음 페이지
    {
      let startPage = this.state.endPage + 1;
      let endPage = startPage + this.maxPageNum;

      if (startPage > this.state.pageCount)
        startPage = this.state.pageCount;
      
      if (endPage > this.state.pageCount)
        endPage = this.state.pageCount;

      this.setState((prevState) => ({...prevState, currentPage : startPage, startPage : startPage, endPage : endPage}));
      this.loadAds(startPage);
    }
    else //특정 페이지 선택
    {
      this.setCurrentPage(pageNumber);
      this.loadAds(pageNumber);
    }
  }

  handleTalk = () =>
  {
    gotoTalkPage();
  }
   
  handleAdsTypeMenu = () =>
  {
    if (this.state.showAdsTypeSel)
      return;

    this.showAdsTypeSel(true);
  }

  handleSelectAdsType = (adsType) =>
  {
    if (LOG_ENABLE)
      console.log(`handleAdsSel : ${adsType}`);

    if (adsType !== -2)
      this.setAdsType(adsType);

    this.showAdsTypeSel(false);
  }

  //주문내역 광고의 링크정보를 클릭함
  handleAdsLink = (item) =>
  {
    window.open(item.lk, "_blank");
  }

  //as요청
  //mode : 문의 접수(0), 문의 내용확인(1), 문의완료내용확인(2)
  handleAs = (mode, item) =>
  {
    if (this.state.loading)
      return;

    if (mode === 0)
    {
      this.editItem = item;
      this.showAsPop(true);
    }
    else if (mode === 1 || mode === 2)
    {
      this.setLoading(true);
      FaqStoreInstance.loadQuestionOneByAidx(item.idx, this.onLoadUserQuery);
    }
  }

  onLoadUserQuery = (resultCode, questionInfo) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert(`문의 내역 조회 중 오류가 발생했습니다.\n잠시 후 이용해 주세요.\n오류코드 ${resultCode}`);
      return;
    }

    this.editItem = questionInfo;
    this.showAsDetail(true);
  }

  //as팝업에서 신청함
  handleAsDo = (item, comment) =>
  {
    if (this.state.loading)
      return;

    if (!item)
    {
      alert(`주문 내역 오류!!`);
      return;
    }

    if (!IsValidS(comment))
    {
      alert(`AS문의 내역을 300자 범위내에서 입력해 주세요.`);
      return;
    }

    if (!window.confirm('AS 문의 내역을 전송 할까요?'))
      return;

    this.setLoading(true);
    FaqStoreInstance.sendQuestion(item.idx, comment, this.onQuestionResult);
  }

  onQuestionResult = (resultCode, adsIdx) =>
  {
    this.setLoading(false);
    this.showAsPop(false);
    
    if (resultCode === 0)
    {
      alert(`AS 문의 접수가 완료되었습니다.`);

      if (IsValidS(this.state.orderList))
      {
        let newList = [];

        for (let i = 0;i < this.state.orderList.length; ++i)
        {
          if (this.state.orderList[i].idx !== adsIdx)
            newList.push(this.state.orderList[i]);
          else
            newList.push({...this.state.orderList[i], ast: 1});
        }

        this.setOrderList(newList);
      }

      return;
    }
    else
    {
      alert(`문의 내역 접수 중 오류가 발생했습니다.\n잠시 후 이용해 주세요.\n오류코드 ${resultCode}`);
      return;
    }
  }

  //진행중 광고의 취소를 요청함
  handleCancelAds = (item) =>
  {
    if (this.state.loading)
      return;

    if (!window.confirm("주문 취소 접수를 하시겠습니까?"))
      return;

    this.setLoading(true);
    AdsStoreInstance.requestCancel(item.idx, this.onCancelResult);
  }

  onCancelResult = (resultCode, adsIdx, newStatus) =>
  {
    this.setLoading(false);

    if (resultCode === 0)
    {
      if (IsValidS(this.state.orderList))
      {
        let newList = [];
  
        for (let i = 0;i < this.state.orderList.length; ++i)
        {
          if (this.state.orderList[i].idx !== adsIdx)
            newList.push(this.state.orderList[i]);
          else
          {
            newList.push({
              ...this.state.orderList[i],
              st: newStatus
            });
          }
        }
  
        this.setOrderList(newList);
      }

      alert(`주문 취소가 정상 접수 되었습니다.`);
    }
    else
    {
      switch(resultCode)
      {
        case 1:
          alert(`잘못된 요청입니다.`);
          return;
        case 2:
          alert(`광고가 존재하지 않습니다.`);
          return;
        case 3:
          alert(`해당 광고는 현재 취소가 불가능한 상태입니다.`);
          return;
        case 5:
          alert(`서버점검이 예약되어 있어, 현재 광고 취소가 불가능합니다.`);
          return;
        default:
          alert(`광고 취소중 오류가 발생했습니다.\n잠시 후 이용해 주세요.`);
          return;
      }
    }
  }

  handlePrepareAds = (item) =>
  {
    this.showToastMessage(true, `내부 시스템을 통해 광고 시작을 준비중입니다.\n처리 완료시 해당 광고는 자동으로 노출 시작 됩니다.`);
  }

  renderPc = (orderItems) =>
  {
    return (
      <div className='pgcont coltc'>
        {
          this.state.showLogin ? (<Login handleLoginEvent={this.handleLoginEvent}/>) : null
        }
        {
          this.state.showFindId ? (<FindId handleRegister={this.handleRegister} handleClose={() => this.showFindId(false)} handleFindPw={this.handleFindPw}/>) : null
        }
        {
          this.state.showFindPw ? (<FindPw handleClose={() => this.showFindPw(false)} handleRegister={this.handleRegister} />) : null
        }
        {
          this.state.showToastMessage ? (
            <ToastMessage msg={this.state.toastMessage}/>
          ) : null
        }
        {
          this.state.showMobileMenu ? (<MobileMenu handleNavi={this.handleNavi} handleMenu={this.handleMenu} />) : null
        }
        {
          this.state.showTerm ? (<Term handleClose={() => this.showTerm(false)}/>) : null
        }
        {
          this.state.showPrivacy ? (<Privacy handleClose={() => this.showPrivacy(false)}/>) : null
        }
        {
          this.state.showAs ? (
            <OrderAs item={this.editItem} handleAs={this.handleAsDo} handleClose={() => this.showAsPop(false)}/>
          ) : null
        }
        {
          this.state.showAsDetail ? (<UserQueryDetail item={this.editItem} handleClose={() => this.showAsDetail(false)}/>) : null
        }
        {
          this.state.loading ? (<Loading />) : null
        }

        <Talk handleTalk={this.handleTalk} />

        <TopBar handleNavi={this.handleNavi} withLine={true}/>

        <div className='pgcont2 rowlt'>
          <NaviBar width={this.state.naviWidth} height={this.state.height} handleSnsOrder={this.handleSnsOrder} handleNavi={this.handleNavi} handleMenu={this.handleMenu}/>

          <div className='homecont colel' style={{width: this.state.contWidth, height: this.state.height}}>
            <div className='homecont2 coltl' style={{width: this.state.contWidth - 40 - 50, height: this.state.height - 100}}>

              <label className='font20 wt600 color31 mgtop20'>SNS 주문내역</label>

              <div className='wd100p rowlc mgtop20'>
                <div className='colel'>
                  <label className='font15 wt500 color31 mgbt5'>SNS주소</label>
                  <input type='text' className='olserinp font16 wt400 color31 mgrt10' value={this.state.searchWord} onChange={e => this.setSearchWord(e.target.value)}
                    placeholder='SNS 주소를 입력해 주세요.'
                    onKeyUp={this.handleKeyPress}
                  />
                </div>

                <div className='colel mgrt10'>
                  <label className='font15 wt500 color31 mgbt5'>주문 상태</label>
                  <select className='odlsel' value={this.state.orderStatus.toString()} onChange={(e) => this.setOrderStatus(parseInt(e.target.value))}>
                    <option value="0">무관</option>
                    <option value="1">진행중</option>
                    <option value="2">종료</option>
                  </select>
                </div>

                <div className='colel mgrt10'>
                  <label className='font15 wt500 color31 mgbt5'>성별</label>
                  <select className='odlsel' value={this.state.gender.toString()} onChange={(e) => this.setGenderMode(parseInt(e.target.value))}>
                    <option value="0">무관</option>
                    <option value="1">남성</option>
                    <option value="2">여성</option>
                  </select>
                </div>

                <div className='colel mgrt10'>
                  <label className='font15 wt500 color31 mgbt5'>연령</label>
                  <select className='odlsel' value={this.state.age.toString()} onChange={(e) => this.setAgeMode(parseInt(e.target.value))}>
                    <option value="0">무관</option>
                    <option value="1">20대</option>
                    <option value="2">30대</option>
                  </select>
                </div>

                {/*<div className='colel mgrt10'>
                  <label className='font15 wt500 color31 mgbt5'>반복주문</label>
                  <select className='odlsel' value={this.state.repeat.toString()} onChange={(e) => this.setRepeatMode(parseInt(e.target.value))}>
                    <option value="0">전체</option>
                    <option value="1">반복</option>
                    <option value="2">미반복</option>
                  </select>
                </div>*/}

                <div className='colel mgrt10'>
                  <label className='font15 wt500 color31 mgbt5'>&nbsp;</label>
                  <button className='odlserbtn font16 wt600 color20 btncur' onClick={this.handleSearch}>검색</button>
                </div>

                {
                  this.state.searchMode ? (
                    <div className='colel'>
                      <label className='font15 wt500 color31 mgbt5'>&nbsp;</label>
                      <button className='odlserbtn font16 wt600 color20 btncur' onClick={this.handleDoneSearch}>초기화</button>
                    </div>
                  ) : null
                }
              </div>

              <div className='oladstype flexrowsc mgtop10 btncur' onClick={this.handleAdsTypeMenu}>
                <div className='flexrowcc olselbgmgl'>
                  {this.getAdsTypeImg()}
                  <label className='font16 wt400 colorb3 btncur'>&nbsp;{AdsTypeToText2(this.state.adsType)}</label>
                </div>

                <img src={GetImgUrl("com/down.png")} alt="확장" className='olselbgmgr'/>
                {
                  this.state.showAdsTypeSel ? (
                    <OrderListSel handleAdsSel={this.handleSelectAdsType}/>
                  ) : null
                }
              </div>

              <div className='olitemlist coltc mgtop20' style={{height: this.state.height - 314}}>
                <table className='odltable'>
                  <thead>
                    <tr>
                      <th className="odltbcell">
                        <label className="font15 wt500 color31">남은 수량</label>
                      </th>
                      <th className="odltbcell2">
                        <label className="font15 wt500 color31">주문 번호</label>
                      </th>

                      <th className="odltbcell2">
                        <label className="font15 wt500 color31">주문 일자</label>
                      </th>

                      <th className="odltbcell2">
                        <label className="font15 wt500 color31">상품 유형</label>
                      </th>

                      <th className="odltbcell2">
                        <label className="font15 wt500 color31">SNS 주소</label>
                      </th>

                      <th className="odltbcell2">
                        <label className="font15 wt500 color31">주문 수량</label>
                      </th>

                      <th className="odltbcell2">
                        <label className="font15 wt500 color31">성별</label>
                      </th>

                      <th className="odltbcell2">
                        <label className="font15 wt500 color31">연령</label>
                      </th>

                      <th className="odltbcell2">
                        <label className="font15 wt500 color31">사용 포인트</label>
                      </th>

                      <th className="odltbcell3">
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderItems}
                  </tbody>
                </table>

                {
                  this.state.pageCount > 1 ? (
                    <Paging
                      needAfter={this.state.startPage > 1}
                      needNext={this.state.endPage < this.state.lastPage}
                      startPage={this.state.startPage}
                      endPage={this.state.endPage}
                      currentPage={this.state.currentPage}
                      pgStyle="margintop35"
                      handlePage={this.handlePage}
                    />
                  ) : null
                }  

              {
                !IsValidS(orderItems) ? (
                  <div className='wd100p colcc mgtop160'>
                    <label className='font20 wt500 color31'>등록된 SNS 주문이 없어요</label>

                    <button className='odlnewodbtn font16 wt600 color20 btncur mgtop10' onClick={() => this.handleSnsOrder(0)}>생성하기</button>
                  </div>
                ) : null
              }
              </div>
            </div>

            <FooterBar handleMenu={this.handleMenu} />
          </div>
        </div>
      </div>
    );
  }

  renderMobile = (orderItems) =>
  {
    return (
      <div className='olbg_mb flexcoltc'>
        {
          this.state.showToastMessage ? (
            <ToastMessage msg={this.state.toastMessage}/>
          ) : null
        }
        {
          this.state.showMobileMenu ? (<MobileMenu handleNavi={this.handleNavi} handleMenu={this.handleMenu} userPoint={LoginStoreInstance.getUserPoint} />) : null
        }
        {
          this.state.showTerm ? (<Term handleClose={() => this.showTerm(false)}/>) : null
        }
        
        {
          this.state.showPrivacy ? (<Privacy handleClose={() => this.showPrivacy(false)}/>) : null
        }
        {
          this.state.showAs ? (
            <OrderAs item={this.editItem} handleAs={this.handleAsDo} handleClose={() => this.showAsPop(false)}/>
          ) : null
        }
        {
          this.state.loading ? (<Loading />) : null
        }

        <Talk handleTalk={this.handleTalk} />

        <TopBar handleNavi={this.handleNavi} handleMenu={this.handleMenu} selected={1} withLine={true}/>

        <div className='olcont_mb flexcoltl'>
          <label className='font18b margintop30'>주문내역</label>
              
          <div className='flexrowcc margintop25'>
            <label className='font14b_2 oltablabel'>성별</label>

            {this.getModeBtn(this.state.gender === 0, "무관", true, 0)}
            {this.getModeBtn(this.state.gender === 2, "여자", true, 2)}
            {this.getModeBtn(this.state.gender === 1, "남자", false,1)}
          </div>

          <div className='flexrowcc margintop10'>
            <label className='font14b_2 oltablabel'>연령</label>

            {this.getModeBtn(this.state.age === 0, "무관", true, 3)}
            {this.getModeBtn(this.state.age === 1, "20대", true, 4)}
            {this.getModeBtn(this.state.age === 2, "30대", false,5)}
          </div>


          {/*<div className='flexrowcc margintop10'>
            <label className='font14b_2 oltablabel'>반복주문</label>

            {this.getModeBtn(!this.state.repeat, "미설정", true, 6)}
            {this.getModeBtn(this.state.repeat, "설정", true, 7)}
          </div>*/}

          <div className='flexrowsc olserarea_mb margintop40'>
            <input type='text' className='olserinp_mb font14b_2' value={this.state.searchWord} onChange={e => this.setSearchWord(e.target.value)}
              placeholder='SNS 주소를 입력해 주세요.'
              onKeyUp={this.handleKeyPress}
            />
            <img src={GetImgUrl("com/search.png")} alt="검색" className='marginright10 btncur' onClick={this.handleSearch}/>
          </div>
          <div className='olserline'></div>

          <div className='oladstype_mb flexrowsc margintop48 btncur' onClick={this.handleAdsTypeMenu}>
            <div className='flexrowcc olselbgmgl'>
              {this.getAdsTypeImg()}
              <label className='font14b_2 btncur'>&nbsp;{AdsTypeToText2(this.state.adsType)}</label>
            </div>

            <img src={GetImgUrl("com/mb/down.png")} alt="확장" className='olselbgmgr img107'/>
            {
              this.state.showAdsTypeSel ? (
                <OrderListSel handleAdsSel={this.handleSelectAdsType}/>
              ) : null
            }
          </div>

          {
            this.state.searchMode ? (
              <div className='flexrowrc fullwidth margintop20' onClick={this.handleDoneSearch}>
                <button className='olstbtn_mb btncur font1420'>목록보기</button>
              </div>
            ) : null
          }

          <div className='flexrowcc olitemlist margintop50'>
            {orderItems}
          </div>

          {
            this.state.pageCount > 1 ? (
              <Paging
                needAfter={this.state.startPage > 1}
                needNext={this.state.endPage < this.state.lastPage}
                startPage={this.state.startPage}
                endPage={this.state.endPage}
                currentPage={this.state.currentPage}
                pgStyle="margintop35"
                handlePage={this.handlePage}
              />
            ) : null
          }  
        </div>

        <div className='olempty_mb'></div>
        <FooterBar handleMenu={this.handleMenu} />
      </div>
    );
  }

  render()
  {
    let orderItems = null;

    /*if (mobileOn)
    {
      if (IsValidS(this.state.orderList))
      {
        let index = 0;
        orderItems = this.state.orderList.map((item) => {
          ++index;
          return <OrderListItemMb key={item.idx} item={item} handleAdsLink={this.handleAdsLink} handleAs={this.handleAs} handleCancel={this.handleCancelAds} handlePrepareAds={this.handlePrepareAds}/>
        });
      }

      return this.renderMobile(orderItems);
    }
    else*/
    {
      if (IsValidS(this.state.orderList))
      {
        let index = 0;
        orderItems = this.state.orderList.map((item) => {
          ++index;
          return <OrderListItem key={item.idx} item={item} handleAdsLink={this.handleAdsLink} handleAs={this.handleAs} handleCancel={this.handleCancelAds} handlePrepareAds={this.handlePrepareAds}/>
        });
      }

      return this.renderPc(orderItems);
    }
  }
}

export default withNavigation(OrderList);