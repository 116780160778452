import React, { Fragment } from 'react';
import './Bingo.css';
import '../../App.css';
import { GetImgUrl } from '../../URL/AppUrl';
import {  getBingoDefaultPrice, GetDateToSerialDate2, GetFormattedNum, IsValidS,  } from '../../Util/Util';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/locale";

//빙고 재집행 주문창
class BingoReorder extends React.Component
{
  state = {
    selectedDate: null,
    requestDate: 0, //원하는 일자
    count: 1, //구매개수
    payMode: 0, //결제 방식, 카드(0), 가상계좌(1), 계좌이체(2)
    wName: '', //입금자명
  };

  setSelectedDate = (v) =>
  {
    this.setState((prevState) => ({...prevState, selectedDate : v}));

    if (v)
      this.setRequestDate(GetDateToSerialDate2(v));
  }
    
  setRequestDate = (v) =>
  {
    this.setState((prevState) => ({...prevState, requestDate : v}));
  }

  setCount = (v) =>
  {
    this.setState((prevState) => ({...prevState, count : v}));
  }

  setPayMode = (v) =>
  {
    this.setState((prevState) => ({...prevState, payMode : v}));
  }

  setWname = (v) =>
  {
    this.setState((prevState) => ({...prevState, wName : v}));
  }

  //사이징----------------------------------------------------------------------------------------------
  
  componentDidMount() {
    /*const date = new Date();
    date.setDate(date.getDate() + 5);

    this.setSelectedDate(date);
    this.setRequestDate(GetDateToSerialDate2(date));*/
  }

  componentWillUnmount = () =>
  {
  }

  handleAddCount = (addCount) =>
  {
    let newCount = this.state.count + addCount;

    if (newCount < 1)
      return;

    this.setCount(newCount);

    if ((newCount * this.getBaseAmount()) > 1000000)
    {
      this.setPayMode(2);
    }
  }

  //광고 1개의 가격
  getBaseAmount = () =>
  {
    return getBingoDefaultPrice(this.props.item.gt);
  }

  //구매 개수 만큼의 가격
  getTotalAmount = () =>
  {
    return this.state.count * this.getBaseAmount();
  }

  handlePurchase = () =>
  {
    if (this.state.requestDate === 0)
    {
      alert(`광고 시작 희망 일자를 선택해 주세요.`);
      return;
    }

    switch(this.state.payMode)
    {
      case 0:
      case 1:
      case 2:
        break;
      default:
        alert(`결제 방식을 선택해 주세요.`);
        return;
    }

    if (this.state.payMode === 2 && !IsValidS(this.state.wName))
    {
      alert(`입금자명을 입력해 주세요.`);
      return;
    }

    this.props.handleReorder(this.props.item, this.state.count, this.state.payMode, this.state.wName, this.state.requestDate);
  }

  render()
  {
    return (
      <div className='reodpopbg colcc'>
        <div className='reodpop colcc'>
          <div className='reodpopin colcc'>
            <div className='wd100p rowsc'>
              <label className='font20 wt600 color31'>광고 재집행</label>

              <img src={GetImgUrl("com/close.png")} alt="닫기" className='btncur' onClick={() => this.props.handleClose()}/>
            </div>

            <div className='wd100p coltl mgtop10'>
              <label className='font15 wt500 color31'>광고 시작일</label>

              <div className='reodpopdate rowlc mgtop5'>
                <DatePicker selected={this.state.selectedDate} onChange={(date) => {this.setSelectedDate(date); console.log(`date selected ${date}`);}} showTimeSelect fixedHeight
                  placeholderText='날짜를 선택해 주세요'
                    locale={ko}
                    //timeFormat="p"
                    //timeIntervals={30}
                    //dateFormat="Pp"
                    dateFormat="yyyy-MM-dd h:mm aa"

                  customInput={<input type='text' className='bingodateinp2 font16 fflv1 color1e' readOnly={true}
                  />}
                />
              </div>

              <label className='font15 wt500 color31 mgtop25'>광고 비용<span className='font12 colorb3 mglt5'>(VAT제외)</span></label>

              <div className='reodpopdate rowsc mgtop5'>
                <label className='font16 wt400 colorb3'>{`${GetFormattedNum(this.getTotalAmount())}원`}</label>

                <div className='rowcc'>
                  <img src={GetImgUrl("bingo/mn.png")} alt="감소" className='mgrt10 btncur' onClick={() => this.handleAddCount(-1)}/>
                  <img src={GetImgUrl("bingo/pl.png")} alt="증가" className='btncur'  onClick={() => this.handleAddCount(1)}/>
                </div>
              </div>

              <div className='reodpopbox colevl mgtop10'>
                <div className='rowcc'>
                  <label className='reodpopboxtext font16 wt500 color31'>예상 플레이수</label>
                  <label className='font16 wt400 color31 mglt10'>1000회</label>
                </div>
                
                <div className='rowcc'>
                  <label className='reodpopboxtext font16 wt500 color31'>예상 동영상 시청수</label>
                  <label className='font16 wt400 color31 mglt10'>1000회</label>
                </div>
              </div>

              <label className='font15 wt500 color31 mgtop25'>결제 방식</label>

              {
                this.getTotalAmount() > 1000000 ? (
                  <button className='reodbtn3 font16 wt500 color20 mgtop10'>계좌이체</button>
                ) : (
                  <div className='wd100p rowsc mgtop10'>
                    <button className={this.state.payMode === 0 ? 'reodbtn1 font16 wt500 color20 btncur' : 'reodbtn2 font16 wt500 color31 btncur'} onClick={() => this.setPayMode(0)}>카드</button>
                    <button className={this.state.payMode === 1 ? 'reodbtn1 font16 wt500 color20 btncur' : 'reodbtn2 font16 wt500 color31 btncur'} onClick={() => this.setPayMode(1)}>가상계좌</button>
                    <button className={this.state.payMode === 2 ? 'reodbtn1 font16 wt500 color20 btncur' : 'reodbtn2 font16 wt500 color31 btncur'} onClick={() => this.setPayMode(2)}>계좌이체</button>
                  </div>
                )
              }
              
              {
                this.state.payMode === 2 ? (
                  <input type='text' value={this.state.wName} onChange={e => this.setWname(e.target.value)} className='reodinp font16 wt400 color31 mgtop10' placeholder='입금자명을 입력해 주세요'/>
                ) : null
              }

              <div className='reoddescbox mgtop30 colcl lht23'>
                <div className='rowcc'>
                  <label className='font15 wt400 colorff36 mgrt5'>·</label>
                  <label className='font15 wt400 colorff36'>광고 집행 게시 이후에는 환불이 불가능합니다.</label>
                </div>

                <div className='rowcc'>
                  <label className='font15 wt400 color31 mgrt5'>·</label>
                  <label className='font15 wt400 color31'>광고 재집행은 기존에 입력한 내용으로 진행되며 수정이 불가능합니다.</label>
                </div>

                <div className='rowcc'>
                  <label className='font15 wt400 color31 mgrt5'>·</label>
                  <label className='font15 wt400 color31'>결제 후 미리 보기를 통해 게시될 빙고를 확인하고 승인을 완료하면 광고가 게시됩니다.</label>
                </div>

                <div className='rowcc'>
                  <label className='font15 wt400 color31 mgrt5'>·</label>
                  <label className='font15 wt400 color31'>광고 진행 도중 오류가 발생할 경우, 관리자에 의해 즉시 광고가 중단될 수 있습니다.</label>
                </div>

                <div className='rowcc'>
                  <label className='font15 wt400 color31 mgrt5'>·</label>
                  <label className='font15 wt400 color31'>광고 진행 도중 기재된 내용이 제출된 내용과 상이할 경우 관리자에 의해 내용이 임의로 변경될 수 있습니다.</label>
                </div>

                <div className='rowcc'>
                  <label className='font15 wt400 color31 mgrt5'>·</label>
                  <label className='font15 wt400 color31'>세금계산서는 요청 시 1영업일 이내에 발행됩니다.</label>
                </div>
              </div>

              <button className='reodbtn4 font16 wt600 colorw mgtop20 btncur' onClick={this.handlePurchase}>결제하기</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BingoReorder;