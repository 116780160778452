import React from 'react';
import './Notice.css';
import '../../App.css';
import { GetImgUrl, LOG_ENABLE, gotoFirstPage, gotoTalkPage, handleAppNavi, mobileOn, withNavigation } from '../../URL/AppUrl';
import {  IsValidS, } from '../../Util/Util';
import Loading from '../Loading/Loading';
import TopBar from '../Top/TopBar';
import FooterBar from '../Footer/Footer';
import { LoginStoreInstance } from '../../Stores/LoginStore';
import Term from '../Term/Term';
import Privacy from '../Term/Privacy';
import Paging from '../Paging/Paging';
import NoticeItem from './NoticeItem';
import Talk from '../Talk/Talk';
import MobileMenu from '../Top/MobileMenu';
import NoticeItemMb from './NoticeItemMb';
import { NoticeStoreInstance } from '../../Stores/NoticeStore';
import NaviBar from '../Navi/Navi';
import { AdsStoreInstance } from '../../Stores/AdsStore';
import { resetAllUserData } from '../../Stores/StoreManager';
import Login from '../Login/Login';
import FindId from '../Login/FindId';
import FindPw from '../Login/FindPw';

class Notice extends React.Component
{
  state = {
    loading: false,
    showTerm: false,
    showPrivacy: false,
    showLogin: false,
    showFindId: false,
    showFindPw: false,
    showMobileMenu: false,
    searchWord: '',
    searchMode: false,
    noticeMode: -1, //전체(-1), 취소/환불(0), 서비스(1)
    noticeList:  null,
    totalNoticeCount: 0,

    startPage: 1, //현재의 첫페이지
    endPage: 1, //현재의 끝페이지
    lastPage: 1, //마지막 페이지번호
    pageCount: 0, //전체 페이지의 개수
    currentPage: 1, //현재 선택된 페이지

    //사이징처리
    naviWidth: 0,
    contWidth: 0,

    height: 0,
  };

  intervalId = -1;
  noticeMode = -1;//전체(-1), 취소/환불(0), 서비스(1)
  maxPageNum = 5;

  update = () =>
  {
    if (LoginStoreInstance.sessionError && LoginStoreInstance.loggedIn)
    {
      LoginStoreInstance.sessionError = false;
      alert(`인증 세션이 만료되었습니다.`);
      this.processLogOut();
    }
  }

  showMobileMenu = (v) =>
  {
    this.setState((prevState) => ({...prevState, showMobileMenu: v}));
  }

  setCurrentPage = (v) =>
  {
    this.setState((prevState) => ({...prevState, currentPage: v}));
  }

  setPageInfo = (start, end, lastPage, resetCurrentPage) =>
  {
    if (resetCurrentPage)
      this.setState((prevState) => ({...prevState, startPage: start, endPage: end, lastPage: lastPage, currentPage: start}));
    else
      this.setState((prevState) => ({...prevState, startPage: start, endPage: end, lastPage: lastPage}));
  }

  setNoticeMode = (v) =>
  {
    if (v === this.noticeMode)
      return;

    this.noticeMode = v;
    this.setState((prevState) => ({...prevState, noticeMode: v}));
  }

  setNoticeList = (v) =>
  {
    this.setState((prevState) => ({...prevState, noticeList: v}));
  }

  setNoticeCount = (v) =>
  {
    this.setState((prevState) => ({...prevState, totalNoticeCount: v}));
  }

  setSearchWord = (v) =>
  {
    this.setState((prevState) => ({...prevState, searchWord: v}));
  }

  setSearchMode = (v) =>
  {
    this.setState((prevState) => ({...prevState, searchMode: v}));
  }

  showTerm = (v) =>
  {
    this.setState((prevState) => ({...prevState, showTerm: v}));
  }

  showPrivacy = (v) =>
  {
    this.setState((prevState) => ({...prevState, showPrivacy: v}));
  }
  //-----------------------------------------------------------------------------------------------------------------------------
  showLogin = (v) =>
  {
    this.setState((prevState) => ({...prevState, showLogin: v}));
  }

  showFindId = (v) =>
  {
    this.setState((prevState) => ({...prevState, showFindId: v}));
  }

  showFindPw = (v) =>
  {
    this.setState((prevState) => ({...prevState, showFindPw: v}));
  }

  handleLoginEvent = (eventCode) =>
  {
    if (this.state.loading)
      return;

    switch(eventCode)
    {
      case 0: //닫기
        this.showLogin(false);
        return;
      case 1: //회원가입
        this.handleRegister();
        return;
      case 2: //id찾기
        this.showLogin(false);
        this.showFindId(true);
        return;
      case 3: //비번찾기
        this.showLogin(false);
        this.showFindPw(true);
        return;
      case 100: //로그인 성공
        this.showLogin(false);
        return;
    }
  }

  handleRegister = () =>
  {
    if (this.state.loading)
      return;

    this.showLogin(false);
    this.showFindId(false);
    this.showFindPw(false);
    this.handleNavi(5);
  }

  handleFindPw = () =>
  {
    if (this.state.loading)
      return;

    this.showLogin(false);
    this.showFindId(false);
    this.showFindPw(true);
  }
  //-----------------------------------------------------------------------------------------------------------------------------
  handleNavi = (naviIndex) =>
  {
    if (this.state.loading)
      return;

    if (LOG_ENABLE)
      console.log(`handleNavi : ${naviIndex}`);

    //console.log(`${this.props.navigate}`);
    this.showMobileMenu(false);
    handleAppNavi(this.props.navigate, naviIndex);
  }

  handleSnsOrder = (orderType) =>
  {
    if (this.state.loading)
      return;

    if (LOG_ENABLE)
      console.log(`handleSnsOrder : ${orderType}`);

    AdsStoreInstance.setOrderType(orderType);

    handleAppNavi(this.props.navigate, 0);
  }

  handleMenu = (btnIndex) =>
  {
    if (this.state.loading)
      return;
    
    if (LOG_ENABLE)
      console.log(`handleMenu : ${btnIndex}`);

    switch(btnIndex)
    {
      case 0: //계정설정
        this.showLogin(true);
        return;
      case 6://이용약관
        this.showTerm(true);
        break;
      case 7://개인정보처리방침
        this.showPrivacy(true);
        break;
      case 100: //로그아웃
        if (!window.confirm('로그아웃 할까요?'))
          return;

        this.processLogOut();
        return;
      default:
        return;
    }
  }

  processLogOut = () =>
  {
    resetAllUserData();
    gotoFirstPage();
  }

  //사이징----------------------------------------------------------------------------------------------
  setSize = (navi, cont, height) =>
  {
    this.setState((prevState) => ({...prevState, naviWidth : navi, contWidth : cont, height : height}));
  }

  resetContsize = () =>
  {
    //네비게이션 영역은 전체의 16%정도
    let naviWidth = 0.16 * window.innerWidth;

    if (naviWidth < 300) //최소크기300
      naviWidth = 300;

    //콘텐츠영역크기 계산
    let contWidth = window.innerWidth - naviWidth;

    if (contWidth < 1220)
      contWidth = 1220;

    this.setSize(naviWidth, contWidth, window.innerHeight - 70)
  }

  onResize = () => {
    this.resetContsize();
  }
  //사이징----------------------------------------------------------------------------------------------

  componentDidMount() {
    this.intervalId = setInterval(this.update, 1000);

    this.resetContsize();

    window.addEventListener("resize", this.onResize);

    if (!LoginStoreInstance.loggedIn)
    {
      if (LoginStoreInstance.loadToken()) //인증토큰 정보가 존재한다면
      {
        this.setLoading(true);
        LoginStoreInstance.loadProfile(this.onProfileLoadedForRefresh);
      }
      else
      {
        this.loadNotice(1);
      }
    }
    else
    {
      if (!LoginStoreInstance.isProfileReady) //프로필이 준비되지 않았다면
      {
        this.setLoading(true);
        LoginStoreInstance.loadProfile(this.onProfileLoaded);
      }
      else
      {
        this.loadNotice(1);

        //마지막 포인트 조회 시점부터 현재까지 경과 시간이 1분이상 경과한경우
        if (LoginStoreInstance.getPassedPointTime() > 60)
        {
          LoginStoreInstance.catchPointRefreshTime();
          LoginStoreInstance.refreshUserPoint(null);
        }
      }
    }
  }

  componentWillUnmount = () =>
  {
    if (this.intervalId !== -1)
    {
      clearInterval(this.intervalId);
      this.intervalId = -1;
    }

    window.removeEventListener("resize", this.onResize);
  }

  onProfileLoadedForRefresh = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      LoginStoreInstance.removeToken();
      return;
    }

    this.loadNotice(1);
  }

  onProfileLoaded = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert(`프로필 읽기 중 오류가 발생했습니다.\n다시 로그인해 주세요.`);
      this.processLogOut();
      return;
    }

    this.loadNotice(1);
  }

  loadNotice = (pageNum) =>
  {
    if (!this.state.loading)
      this.setLoading(true);

    NoticeStoreInstance.loadNotice(this.noticeMode, ((pageNum - 1) * 10), this.onLoadNotice);
  }

  onLoadNotice = (resultCode, firstLoading, totalCount, noticeList) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert(`공지 사항 로딩 중 오류가 발생했습니다.\n잠시 후 이용해 주세요.\n오류코드 : ${resultCode}`);
      return;
    }

    if (firstLoading)
    {
      this.setNoticeCount(totalCount);
      this.calcPageCount(totalCount);
    }

    if (IsValidS(noticeList))
      this.setNoticeList([...noticeList]);
    else
      this.setNoticeList(null);
  }

  //전체 페이지 개수를 카운트
  calcPageCount = (noticeCount) =>
  {
    let pageCount = Math.floor(noticeCount / 10);

    if ((noticeCount % 10) !== 0)
      ++pageCount;

    let endPage = 5;

    if (endPage > pageCount)
      endPage = pageCount;

    this.setState((prevState) => ({...prevState, startPage : 1, endPage : endPage, currentPage : 1, pageCount : pageCount, lastPage: pageCount}));
  }

  setLoading = (v) =>
  {
    this.setState((prevState) => ({...prevState, loading : v}));
  }

  getNoticeBtn = (enabled, caption, widthMargin, index) =>
  {
    if (mobileOn)
    {
      if (enabled)
      {
        return <button className={`ntcbtn1_mb btncur font14btn2 ${widthMargin ? "marginright5" : ""}`} onClick={() => this.handleTab(index)}>{caption}</button>
      }
      else
      {
        return <button className={`ntcbtn2_mb btncur font14btn3 ${widthMargin ? "marginright5" : ""}`} onClick={() => this.handleTab(index)}>{caption}</button>
      }
    }
    else
    {
      if (enabled)
      {
        return <button className={`ntcbtn1 btncur font14btn2 ${widthMargin ? "marginright5" : ""}`} onClick={() => this.handleTab(index)}>{caption}</button>
      }
      else
      {
        return <button className={`ntcbtn2 btncur font14btn3 ${widthMargin ? "marginright5" : ""}`} onClick={() => this.handleTab(index)}>{caption}</button>
      }
    }
  }

  handleTab = (index) =>
  {
    if (index === this.state.noticeMode || this.state.loading)
      return;

    this.setNoticeMode(index);

    this.loadNotice(1);
  }

  //페이지 선택처리
  handlePage = (pageNumber) =>
  {
    if (pageNumber === -1) //이전 페이지
    {
      let endPage = this.state.startPage - 1;

      let startPage = endPage - (this.maxPageNum - 1);
      

      if (startPage < 1)
        startPage = 1;
      
      if (endPage > this.state.pageCount)
        endPage = this.state.pageCount;

      this.setState((prevState) => ({...prevState, currentPage : startPage, startPage : startPage, endPage : endPage}));

      this.loadNotice(startPage);
    }
    else if (pageNumber === -2) //다음 페이지
    {
      let startPage = this.state.endPage + 1;
      let endPage = startPage + (this.maxPageNum - 1);

      if (startPage > this.state.pageCount)
        startPage = this.state.pageCount;
      
      if (endPage > this.state.pageCount)
        endPage = this.state.pageCount;

      this.setState((prevState) => ({...prevState, currentPage : startPage, startPage : startPage, endPage : endPage}));
      this.loadNotice(startPage);
    }
    else //특정 페이지 선택
    {
      this.setCurrentPage(pageNumber);
      this.loadNotice(pageNumber);
    }
  }

  handleKeyPress = (e) =>
  {
    if (LOG_ENABLE)
      console.log("handleKeyPress");

    if (e.key === "Enter")
    {
      this.handleSearch();
    }
  }

  handleSearch = () =>
  {
    if (this.state.loading)
      return;

    if (!IsValidS(this.state.searchWord))
    {
      alert('검색어를 입력해 주세요.');
      return;
    }

    this.setLoading(true);
    NoticeStoreInstance.searchNotice(this.noticeMode, this.state.searchWord, this.onSearchResult);
  }

  onSearchResult = (resultCode, list) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert(`공지 검색 중 오류가 발생했습니다.\n오류코드 : ${resultCode}`);
      return;
    }

    this.setSearchMode(true);

    if (IsValidS(list))
      this.setNoticeList([...list]);
    else
      this.setNoticeList(null);
  }

  handleTalk = () =>
  {
    gotoTalkPage();
  }

  renderPC = (noticeList) =>
  {
    return (
      <div className='pgcont coltc'>
        {
          this.state.showMobileMenu ? (<MobileMenu handleNavi={this.handleNavi} handleMenu={this.handleMenu} />) : null
        }
        {
          this.state.showLogin ? (<Login handleLoginEvent={this.handleLoginEvent}/>) : null
        }
        {
          this.state.showFindId ? (<FindId handleRegister={this.handleRegister} handleClose={() => this.showFindId(false)} handleFindPw={this.handleFindPw}/>) : null
        }
        {
          this.state.showFindPw ? (<FindPw handleClose={() => this.showFindPw(false)} handleRegister={this.handleRegister} />) : null
        }
        {
          this.state.showTerm ? (<Term handleClose={() => this.showTerm(false)}/>) : null
        }
        {
          this.state.showPrivacy ? (<Privacy handleClose={() => this.showPrivacy(false)}/>) : null
        }
        {
          this.state.loading ? (<Loading />) : null
        }

        <TopBar handleNavi={this.handleNavi} withLine={true}/>

        <div className='pgcont2 rowlt'>
          <NaviBar width={this.state.naviWidth} height={this.state.height} handleSnsOrder={this.handleSnsOrder} handleNavi={this.handleNavi} handleMenu={this.handleMenu}/>

          <div className='homecont colel' style={{width: this.state.contWidth, height: this.state.height}}>
            <div className='homecont2 coltl' style={{width: this.state.contWidth - 40 - 50, height: this.state.height - 100}}>
              <div className='flexrowse ntcsearcharea mgtop20'>
                <div className='flexcolel'>
                  <label className='font20 wt600 color31'>공지사항</label>
                </div>
              </div>

              <div className='ntcitemarea flexcolcc margintop28'>
                <table className='ntctable'>
                  <thead>
                    <tr>
                      <th className="ntctbcell">
                        <label className="font15 wt500 color31">번호</label>
                      </th>
                      <th className="ntctbcell2">
                        <label className="font15 wt500 color31">제목</label>
                      </th>

                      <th className="ntctbcell3">
                        <label className="font15 wt500 color31">등록일</label>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {noticeList}
                  </tbody>
                </table>
              </div>

              {
                this.state.pageCount > 1 ? (
                  <Paging
                    needAfter={this.state.startPage > 1}
                    needNext={this.state.endPage < this.state.lastPage}
                    startPage={this.state.startPage}
                    endPage={this.state.endPage}
                    currentPage={this.state.currentPage}
                    pgStyle="margintop50 mgbt50"
                    handlePage={this.handlePage}
                  />
                ) : null
              }          
            </div>
            <FooterBar handleMenu={this.handleMenu}/>
          </div>

        </div>

        <Talk handleTalk={this.handleTalk} />
      </div>
      
      
    );
  }

  renderMobile = (noticeList) =>
  {
    return (
      <div className='ntcbg flexcoltc'>
        {
          this.state.showMobileMenu ? (<MobileMenu handleNavi={this.handleNavi} handleMenu={this.handleMenu} userPoint={LoginStoreInstance.getUserPoint}/>) : null
        }
        {
          this.state.showLogin ? (<Login handleLoginEvent={this.handleLoginEvent}/>) : null
        }
        {
          this.state.showFindId ? (<FindId handleRegister={this.handleRegister} handleClose={() => this.showFindId(false)} handleFindPw={this.handleFindPw}/>) : null
        }
        {
          this.state.showFindPw ? (<FindPw handleClose={() => this.showFindPw(false)} handleRegister={this.handleRegister} />) : null
        }
        {
          this.state.showTerm ? (<Term handleClose={() => this.showTerm(false)}/>) : null
        }
        
        {
          this.state.showPrivacy ? (<Privacy handleClose={() => this.showPrivacy(false)}/>) : null
        }
        
        {
          this.state.loading ? (<Loading />) : null
        }

        <Talk handleTalk={this.handleTalk} />
        
        <TopBar handleNavi={this.handleNavi} handleMenu={this.handleMenu} selected={3} withLine={true}/>
        <div className='ntccont_mb flexcoltl'>

          <label className='font18b margintop30'>공지사항</label>
          <label className='font14b_2'>SNS플라이를 서비스 관련 이용안내 및 공지사항,이벤트를 확인할 수 있습니다.</label>

          <div className='fullwidth flexrowlc margintop30'>
            {this.getNoticeBtn(this.state.noticeMode === -1, "전체", true, -1)}
            {this.getNoticeBtn(this.state.noticeMode === 0, "공지", true, 0)}
            {this.getNoticeBtn(this.state.noticeMode === 1, "이벤트", false, 1)}
          </div>

          <div className='flexcolcr ntcserinparea_mb margintop30'>
            <div className='flexrowsc ntcserinparea_mb'>
              <input type='text' className='font14b_2 ntcinp' value={this.state.searchWord} onChange={(e) => this.setSearchWord(e.target.value)}
                placeholder='검색어를 입력해 주세요.'
                onKeyPress={this.handleKeyPress}
              />
              <img src={GetImgUrl("com/mb/search.png")} alt="검색" className='marginright10 btncur img2222' onClick={this.handleSearch}/>
            </div>
            
            <div className='ntcserline'></div>
          </div>

          

          <div className='ntcitemarea flexcolcc margintop28'>
            {noticeList}
          </div>

          {
            this.state.pageCount > 1 ? (
              <Paging
                needAfter={this.state.startPage > 1}
                needNext={this.state.endPage < this.state.lastPage}
                startPage={this.state.startPage}
                endPage={this.state.endPage}
                currentPage={this.state.currentPage}
                pgStyle="margintop30"
                handlePage={this.handlePage}
              />
            ) : null
          }          

          <div className='fullwidth flexcolcl marginbottom70'>
          </div>
        </div>
        <FooterBar handleMenu={this.handleMenu}/>
      </div>
    );
  }

  render()
  {
    let noticeList = null;

    /*if (mobileOn)
    {
      if (IsValidS(this.state.noticeList))
      {
        let index = 0;
        noticeList = this.state.noticeList.map((item) => {
          ++index;
          return <NoticeItemMb key={item.idx} item={item} withTopLine={index === 1} withBottomLine={true}/>
        });
      }

      return this.renderMobile(noticeList);
    }
    else*/
    {
      if (IsValidS(this.state.noticeList))
      {
        let index = 0;
        noticeList = this.state.noticeList.map((item) => {
          ++index;
          return <NoticeItem key={item.idx} item={item} withTopLine={index === 1} withBottomLine={true}/>
        });
      }

      return this.renderPC(noticeList);
    }
  }
}

export default withNavigation(Notice);

