import React, { Fragment } from 'react';
import './Purchase.css';
import '../../App.css';
import { GetFormattedNum, IsValidS } from '../../Util/Util';
import { APP_MODE, mobileOn } from '../../URL/AppUrl';

class PurchaseBank extends React.Component
{
  state = {
    price: 5000, //충전금액
    bankUserName: '', //무통장입금자명
    payMode: 0, //가상계좌(0), 카드(1)
    taxMode: 0, //신청안함(0), 신청(1)
    contact: '', //담당자 연락처
  };

  setPrice = (v) =>
  {
    this.setState((prevState) => ({...prevState, price : v}));
  }

  setBankUserName = (v) =>
  {
    this.setState((prevState) => ({...prevState, bankUserName : v}));
  }

  setPayMode = (v) =>
  {
    this.setState((prevState) => ({...prevState, payMode : v}));
  }

  setTaxMode = (v) =>
  {
    this.setState((prevState) => ({...prevState, taxMode : v}));
  }

  setContact = (v) =>
  {
    this.setState((prevState) => ({...prevState, contact : v}));
  }

  componentDidMount() {
    
  }

  componentWillUnmount = () =>
  {
  }

  getCurrentPrice = () =>
  {
    let price = Math.floor(this.state.price + (this.state.price * 0.1));

    return GetFormattedNum(price);
  }

  handlePurchase = () =>
  {
    /*if (!IsValidS(this.state.bankUserName))
    {
      alert(`입금자명을 입력해 주세요.`);
      return;
    }*/

    if (this.state.taxMode !== 0 && !IsValidS(this.state.contact))
    {
      alert(`담당자 연락처를 입력해 주세요.`);
      return;
    }

    switch(this.state.price)
    {
      case 100:
      case 5000:
      case 10000:
      case 20000:
      case 30000:
      case 40000:
      case 50000:
      case 100000:
      case 200000:
      case 300000:
      case 400000:
      case 500000:
        break;
      default:
        alert(`충전 금액을 올바르게 선택해 주세요.`);
        return;
    }

    if (this.props.handlePurchase)
      this.props.handlePurchase(this.state.payMode, this.state.price, this.state.bankUserName, this.state.taxMode, this.state.contact);
  }

  render()
  {
    /*if (mobileOn)
    {
      return (
        <div className='purbank_mb flexcoltl'>
          <label className='font18b margintop20'>충전하기</label>
  
          <label className='font14b_2 margintop15'>충전 금액 <span className='font12b3'>&nbsp;VAT별도</span></label>

          <select className='purbanksel_mb font14b_2 margintop5'
            value={this.state.price.toString()} onChange={e => this.setPrice(parseInt(e.target.value))}
          >
            <option value="5000">5,000원</option>
            <option value="10000">10,000원</option>
            <option value="20000">20,000원</option>
            <option value="30000">30,000원</option>
            <option value="40000">40,000원</option>
            <option value="50000">50,000원</option>
            <option value="100000">100,000원</option>
            <option value="200000">200,000원</option>
            <option value="300000">300,000원</option>
            <option value="400000">400,000원</option>
            <option value="500000">500,000원</option>
          </select>

          <label className='font14b_2 margintop25'>세금계산서</label>
  
          <div className='fullwidth flexrowsc margintop5'>
            <div className={this.state.taxMode === 0 ? 'purbox2_mb flexrowcc btncur' : 'purbox1_mb flexrowcc btncur'} onClick={() => this.setTaxMode(0)}>
              <label className={this.state.taxMode === 0 ? 'font1620 btncur' : 'font16b3 btncur'}>신청안함</label>
            </div>
  
            <div className={this.state.taxMode === 1 ? 'purbox2_mb flexrowcc btncur' : 'purbox1_mb flexrowcc btncur'} onClick={() => this.setTaxMode(1)}>
              <label className={this.state.taxMode === 1 ? 'font1620 btncur' : 'font16b3 btncur'}>신청</label>
            </div>
          </div>
          <label className='font12b margintop3'>회원가입시 제출한 사업자 정보로 발행해 드립니다.별도 요청이 있을 경우 1:1문의해주세요.</label>
  
          {
            this.state.taxMode !== 0 ? (
              <Fragment>
                <label className='font14b_2 margintop25'>담당자 연락처</label>
                <input type="text" className='purbankinp_mb font16b margintop5'
                  value={this.state.contact}
                  onChange={e => this.setContact(e.target.value)}
                  placeholder='"-"를 제외하고 입력해 주세요.'
                />
              </Fragment>
            ) : null
          }
          
  
          <button className='purbtn_mb font14btn1 btncur margintop50' onClick={this.handlePurchase}>{`${this.getCurrentPrice()}원 무통장 입금하기`}</button>
  
          <label className='font12b margintop30 lineheight20'>·결제금액이 일치해야 무통장입금처리가 완료됩니다.<br/>·무통장입금 완료 처리는 5분 이내 자동으로 잔액이 충전되며, 충전이 안됐을 경우 문의해 주세요.<br/>·신청 후 익일 23시 59분까지 입금하지 않을 경우 취소 처리됩니다.</label>

          <div className='purline margintop20'></div>
        </div>
      );
    }
    else*/
    {
      return (
        <div className='purbank flexcoltl'>
          <label className='font20 wt600 color31'>충전하기</label>
  
          <div className='fullwidth flexrowsc margintop28'>
            <div className='flexcolel'>
              <label className='font1t wt400 color31'>충전 금액 <span className='font14 colorb3'>&nbsp;VAT별도</span></label>
  
              <select className='purbanksel font16b margintop5' value={this.state.price.toString()} onChange={e => this.setPrice(parseInt(e.target.value))}>
                {APP_MODE === 0 ? (<option value="100">100원</option>) : null}
                <option value="5000">5,000원</option>
                <option value="10000">10,000원</option>
                <option value="20000">20,000원</option>
                <option value="30000">30,000원</option>
                <option value="40000">40,000원</option>
                <option value="50000">50,000원</option>
                <option value="100000">100,000원</option>
                <option value="200000">200,000원</option>
                <option value="300000">300,000원</option>
                <option value="400000">400,000원</option>
                <option value="500000">500,000원</option>
              </select>
            </div>
  
            <div className='flexcolel'>
              <label className='font16b'>입금자명</label>
              <input type="text" className='purbankinp font16b margintop5' value={this.state.bankUserName}
                onChange={e => this.setBankUserName(e.target.value)}
                placeholder='입금자명을 정확히 입력해 주세요.'
                maxLength={20}
              />
            </div>
          </div>
  
          {/*<label className='font15 wt400 color31 margintop28'>결제수단</label>
  
          <div className='fullwidth flexrowsc margintop5'>
            <div className={this.state.payMode === 0 ? 'purbox2 flexrowcc btncur' : 'purbox1 flexrowcc btncur'} onClick={() => this.setPayMode(0)}>
              <label className={this.state.payMode === 0 ? 'font1620 btncur' : 'font16b3 btncur'}>가상계좌</label>
            </div>

            <div className={this.state.payMode === 1 ? 'purbox2 flexrowcc btncur' : 'purbox1 flexrowcc btncur'} onClick={() => this.setPayMode(1)}>
              <label className={this.state.payMode === 1 ? 'font1620 btncur' : 'font16b3 btncur'}>카드</label>
            </div>
          </div>*/}

          <label className='font15 wt400 color31 margintop28'>세금계산서</label>
  
          <div className='fullwidth flexrowsc margintop5'>
            <div className={this.state.taxMode === 0 ? 'purbox2 flexrowcc btncur' : 'purbox1 flexrowcc btncur'} onClick={() => this.setTaxMode(0)}>
              <label className={this.state.taxMode === 0 ? 'font1620 btncur' : 'font16b3 btncur'}>신청안함</label>
            </div>
  
            <div className={this.state.taxMode === 1 ? 'purbox2 flexrowcc btncur' : 'purbox1 flexrowcc btncur'} onClick={() => this.setTaxMode(1)}>
              <label className={this.state.taxMode === 1 ? 'font1620 btncur' : 'font16b3 btncur'}>신청</label>
            </div>
          </div>
          <label className='font12b margintop3'>회원가입시 제출한 사업자 정보로 발행해 드립니다.별도 요청이 있을 경우 1:1문의해주세요.</label>
  
          {
            this.state.taxMode !== 0 ? (
              <Fragment>
                <label className='font15 wt400 color31 margintop28'>담당자 연락처</label>
                <input type="text" className='purbankinp2 font16b margintop5'
                  value={this.state.contact}
                  onChange={e => this.setContact(e.target.value)}
                  placeholder='"-"를 제외하고 입력해 주세요.'
                />
              </Fragment>
            ) : null
          }
  
          <button className='purbtn font14btn1 btncur margintop30' onClick={this.handlePurchase}>{this.state.payMode === 0 ? `${this.getCurrentPrice()}원 계좌 이체하기` : `${this.getCurrentPrice()}원 결제하기`}</button>
  
          {/*<label className='font15 wt400 color31 lht23 margintop10'><span className='colorff36'>·포인트 충전은 SNS 주문하기에만 사용됩니다.</span><br/>·결제금액이 일치해야 무통장입금처리가 완료됩니다.<br/>·무통장입금 완료 처리는 5분 이내 자동으로 잔액이 충전되며, 충전이 안됐을 경우 문의해 주세요.<br/>·신청 후 익일 23시 59분까지 입금하지 않을 경우 취소 처리됩니다.<br/>·충전 내역 중 결제대기 중인 내역을 클릭시 입금 계좌정보 확인이 가능합니다.</label>*/}
          <label className='font15 wt400 color31 lht23 margintop10'><span className='colorff36'>·포인트 충전은 SNS 주문하기에만 사용됩니다.</span><br/>·충전 금액을 선택하고, 입금자명을 입력해 주세요<br/>·계좌이체 버튼을 눌러 주시면 포인트 충전 접수가 완료됩니다.<br/>·아래 계좌로 결제 금액을 입금해 주세요.<br/>·결제금액이 일치해야 입금처리가 완료됩니다.<br/>·입금이 완료되었더라도 포인트 충전에는 시간이 소요될 수 있으며, 영업시간 외에 입금한 경우 충전이 &nbsp;&nbsp;지연될 수 있습니다.<br/>·현재 계좌 이체로 충전한 내역은 표시되지 않습니다.<br/>·입금 계좌 번호는 아래와 같습니다.</label>

          <label className='font15 wt700 color31 lht23 margintop10'>예금주명&nbsp;&nbsp;&nbsp;주식회사 브라보식스<br/>거래은행&nbsp;&nbsp;&nbsp;하나은행<br/>계좌번호&nbsp;&nbsp;&nbsp;321-910042-08605</label>
          

        </div>
      );
    }
  }
}

export default PurchaseBank;