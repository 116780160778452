import './Purchase.css';
import '../../App.css';
import { GetImgUrl, mobileOn } from '../../URL/AppUrl';
import { GetBankNameByCode, GetFormattedNum, getVirtualBankLimitTime } from '../../Util/Util';

const calcOrderPrice = (item) =>
{
  return Math.floor(item.prc + (item.prc * 0.1));
}

const getBankNameInfo = (item) =>
{
  if (item.pmd === 0)
  {
    return GetBankNameByCode(parseInt(item.bi.bc));
  }
  else if (item.pmd === 2)
    return item.bi.bn;

  return "";
}

const PurchaseInfo = (props) =>
{
  if (mobileOn)
  {
    return (
      <div className='purinfobg flexrowcc'>
        <div className='purinfopop_mb flexcoltc'>
          <div className='purinfocont_mb flexrowsc margintop15'>
            <label className='font20 wt600 color31'>무통장입금 안내</label>
          </div>
  
          <div className='purinfocont_mb flexrowlc margintop20'>
            <label className='font15 wt500 color31'>입금계좌 정보</label>
          </div>
  
          <div className='purinfobox_mb flexcolevenl margintop3'>
            <label className='font16 wt400 color31'>예금주명&nbsp;&nbsp;&nbsp;{`${props.item.bi.bun}`}</label>
            <label className='font16 wt400 color31'>거래은행&nbsp;&nbsp;&nbsp;{`${getBankNameInfo(props.item)}`}</label>
            <label className='font16 wt400 color31'>계좌번호&nbsp;&nbsp;&nbsp;{`${props.item.bi.ban}`}</label>
          </div>
  
          <div className='purinfocont_mb flexrowlc margintop20'>
            <label className='font15 wt500 color31'>입금자 정보</label>
          </div>
  
          <div className='purinfobox_mb flexcolevenl margintop3'>
            {
              props.item.pmd === 0 ? (
                <label className='font16 wt400 color31'>입금자명&nbsp;&nbsp;&nbsp;{`${props.item.bi.un}`}</label>
              ) : null
            }
            
            <label className='font16 wt400 color31'>입금금액&nbsp;&nbsp;&nbsp;{`${GetFormattedNum(calcOrderPrice(props.item))}`}</label>
            <label className='font16 wt400 color31'>입금기한&nbsp;&nbsp;&nbsp;{`${getVirtualBankLimitTime(props.item)} 23:59까지`}</label>
          </div>
  
          <button className='purinfobtn_mb btncur font16 wt600 colorw margintop15' onClick={props.handleClose}>확인</button>
        </div>
      </div>
    );
  }
  else
  {
    return (
      <div className='purinfobg flexrowcc'>
        <div className='purinfopop flexcoltc'>
          <div className='purinfocont flexrowsc margintop22'>
            <label className='font20 wt600 color31'>무통장입금 안내</label>
            <img src={GetImgUrl("com/close.png")} alt="닫기" className='btncur' onClick={props.handleClose}/>
          </div>
  
          <div className='purinfocont flexrowlc margintop35'>
            <label className='font15 wt500 color31'>입금계좌 정보</label>
          </div>
  
          <div className='purinfobox flexcolevenl margintop8'>
            <label className='font16 wt400 color31'>{`예금주명 ${props.item.bi.bun}`}</label>
            <label className='font16 wt400 color31'>{`거래은행 ${getBankNameInfo(props.item)}`}</label>
            <label className='font16 wt400 color31'>{`계좌번호 ${props.item.bi.ban}`}</label>
          </div>
  
          <div className='purinfocont flexrowlc margintop30'>
            <label className='font15 wt500 color31'>입금자 정보</label>
          </div>
  
          <div className='purinfobox flexcolevenl margintop8'>
            {
              props.item.pmd === 0 ? (
                <label className='font16b'>{`입금자명 ${props.item.bi.un}`}</label>
              ) : null
            }
            
            <label className='font16b'>{`입금금액 ${GetFormattedNum(calcOrderPrice(props.item))}`}</label>
            <label className='font16b'>{`입금기한 ${getVirtualBankLimitTime(props.item)} 23:59까지`}</label>
          </div>
  
          <button className='purinfobtn btncur font16 wt600 colorw margintop35' onClick={props.handleClose}>확인</button>
        </div>
      </div>
    );
  }
}

export default PurchaseInfo;