import React, { Fragment } from 'react';
import './Bingo.css';
import '../../App.css';
import { GetImgUrl, LOG_ENABLE, getBingoImageUrl, gotoFirstPage, gotoTalkPage, handleAppNavi, mobileOn, withNavigation } from '../../URL/AppUrl';
import {  checkVideoBingo, getBingoButtonTypeName, getBingoDesignName, getBingoGameTypeName, getBingoProductType, getBingoPurchaseMode, getBingoRequestDate, getBingoTargetInfo, GetDataDateAndTime, GetFormattedCNum2, GetFormattedNum, GetFormattedPhone2, getShortedPathName, IsValidS, IsValidV, } from '../../Util/Util';
import Loading from '../Loading/Loading';
import FooterBar from '../Footer/Footer';


//빙고 상세등록 3단계, 마지막
class BingoStep3 extends React.Component
{
  state = {
    loading: false,

    termCheck: false,
  };

  firstMain = true;
  firstSymbol = true;
  firstBanner = true;

  setLoading = (v) =>
  {
    this.setState((prevState) => ({...prevState, loading : v}));
  }

  setTermCheck = (v) =>
  {
    this.setState((prevState) => ({...prevState, termCheck : v}));
  }

  componentDidMount() {
    if (LOG_ENABLE)
      console.log(`BingoStep3.componentDidMount`);
  }

  componentWillUnmount = () =>
  {
    if (LOG_ENABLE)
      console.log(`BingoStep3.componentWillUnmount`);
  }

  //등록하기 버튼 눌림
  handleNext = () =>
  {
    if (this.state.loading)
      return;

    this.props.handleNextStep(5, null);
  }
 
  isVideoValid = () =>
  {
    if (IsValidS(this.props.step2.videoName) && this.props.step2.videoName !== "n")
      return true;

    if (this.props.step2.videoFile)
      return true;

    return false;
  }

  getMainImg = () =>
  {
    return <img id="idmainbn" src={!this.props.step2.mainBannerFile && IsValidS(this.props.step2.mainBannerName) && this.props.step2.mainBannerName !== "n" ? getBingoImageUrl(this.props.step2.mainBannerName) : GetImgUrl("com/white.png")}
      className='bgfnimg' alt="메인배너"
      onLoad={() => {
        console.log(`main loaded`);
        if (this.firstMain && this.props.step2.mainBannerFile)
        {
          this.firstMain = false;
          const preview = document.getElementById("idmainbn");
                      
          const reader = new FileReader();
          reader.onloadend = (evt) => {
            preview.src = reader.result;
          };

          reader.readAsDataURL(this.props.step2.mainBannerFile);
        }
      }}
    />
  }

  getSymbolImg = () =>
  {
    return <img id="idsymbol" src={!this.props.step2.symbolFile && IsValidS(this.props.step2.symbolName) && this.props.step2.symbolName !== "n" ? getBingoImageUrl(this.props.step2.symbolName) : GetImgUrl("com/white.png")}
      className='bgfnimg' alt="메인배너"
      onLoad={() => {
        console.log(`symbol loaded`);
        if (this.firstSymbol && this.props.step2.symbolFile)
        {
          this.firstSymbol = false;
          const preview = document.getElementById("idsymbol");
                      
          const reader = new FileReader();
          reader.onloadend = (evt) => {
            preview.src = reader.result;
          };

          reader.readAsDataURL(this.props.step2.symbolFile);
        }
      }}
    />
  }

  getBannerImg = () =>
  {
    return <img id="idbanner" src={!this.props.step2.bannerFile && IsValidS(this.props.step2.bannerName) && this.props.step2.bannerName !== "n" ? getBingoImageUrl(this.props.step2.bannerName) : GetImgUrl("com/white.png")}
      className='bgfnimg' alt="메인배너"
      onLoad={() => {
        console.log(`banner loaded`);
        if (this.firstBanner && this.props.step2.bannerFile)
        {
          this.firstBanner = false;
          const preview = document.getElementById("idbanner");
                      
          const reader = new FileReader();
          reader.onloadend = (evt) => {
            preview.src = reader.result;
          };

          reader.readAsDataURL(this.props.step2.bannerFile);
        }
      }}
    />
  }

  render()
  {
    return (
      <div className='bingocont colel' style={{width: this.props.contWidth, height: this.props.height}}>
        <div className='bingocont2 coltl' style={{width: this.props.contWidth - 40 - 50, height: this.props.height - 100}}>

          <label className='font20 wt600 color31 mgtop20'>주문 정보를 확인해 주세요</label>

          <div className='wd100p rowlt mgtop20 mgbt50'>
            <div className='bingoodleft2'>
              <div className='bingoodbox1 colcc'>
                <div className='bingoodin2 coltl'>
                  <label className='font20 wt500 color20'>광고 상품 정보</label>

                  <div className='bingodescbox9 colevl mgtop10'>
                    <label className='font15 wt500 colorb3'>상품 분류</label>
                    <label className='font16 wt400 color31'>{getBingoProductType(this.props.step1.pdType)}</label>
                  </div>
                  
                  <div className='bingodescbox9 colevl mgtop10'>
                    <label className='font15 wt500 colorb3'>왼쪽 버튼 URL</label>
                    <label className='font16 wt400 color31'>{this.props.step1.leftLink}</label>
                  </div>

                  <div className='bingodescbox9 colevl mgtop10'>
                    <label className='font15 wt500 colorb3'>왼쪽 버튼 문구</label>
                    <label className='font16 wt400 color31'>{getBingoButtonTypeName(this.props.step1.left)}</label>
                  </div>

                  <div className='bingodescbox9 colevl mgtop10'>
                    <label className='font15 wt500 colorb3'>오른쪽 버튼 URL</label>
                    <label className='font16 wt400 color31'>{this.props.step1.rightLink}</label>
                  </div>

                  <div className='bingodescbox9 colevl mgtop10'>
                    <label className='font15 wt500 colorb3'>오른쪽 버튼 문구</label>
                    <label className='font16 wt400 color31'>{getBingoButtonTypeName(this.props.step1.right)}</label>
                  </div>

                  <div className='wd100p rowrc mgtop30 mgbt10'>
                    <button className='bingobtn10 font16 wt600 color20 btncur' onClick={() => this.props.handleNextStep(8)}>수정하기</button>
                  </div>
                </div>
              </div>
              
              <div className='bingoodbox1 colcc mgtop10'>
                <div className='bingoodin2 coltl'>
                  <label className='font20 wt500 color20'>빙고 생성 정보</label>

                  <div className='bingodescbox9 colevl mgtop10'>
                    <label className='font15 wt500 colorb3'>빙고 디자인</label>
                    <label className='font16 wt400 color31'>{getBingoDesignName(this.props.step2.bingoDesign)}</label>
                  </div>
                  
                  <div className='bingodescbox9 colevl mgtop10'>
                    <label className='font15 wt500 colorb3'>광고 이름</label>
                    <label className='font16 wt400 color31'>{this.props.step2.bingoName}</label>
                  </div>

                  <div className='bingodescbox10 colevl mgtop10'>
                    <label className='font15 wt500 colorb3'>메인 배너 이미지</label>
                    <div className='bingomnbnimg'>
                      {this.getMainImg()}
                    </div>
                  </div>

                  <div className='bingodescbox11 colevl mgtop10'>
                    <label className='font15 wt500 colorb3'>심볼 이미지</label>
                    <div className='bingosbimg'>
                      {this.getSymbolImg()}
                    </div>
                  </div>

                  <div className='bingodescbox12 colevl mgtop10'>
                    <label className='font15 wt500 colorb3'>광고 배너 이미지</label>
                    <div className='bingosmbnbimg'>
                      {this.getBannerImg()}
                    </div>
                  </div>

                  <div className='bingodescbox9 colevl mgtop10'>
                    <label className='font15 wt500 colorb3'>경품 지급</label>
                    <label className='font16 wt400 color31'>{this.props.step2.gift === 0 ? "-" : `${this.props.step2.giftCount}개`}</label>
                  </div>

                  {
                    checkVideoBingo(this.props.item.gt) ? (
                      <div className='bingodescbox92 colevl mgtop10'>
                        <label className='font15 wt500 colorb3'>광고 영상</label>
                        <label className='font16 wt400 color31'>{IsValidS(this.props.step2.videoName) ? this.props.step2.videoName : "-"}</label>
                        {
                          this.props.step2.vos === 1 ? (
                            <label className='font16 wt400 color20'>영상이 없어서 제작대행(유료) 맡기고 싶어요</label>
                          ) : null
                        }
                      </div>
                    ) : null
                  }
                  

                  <div className='wd100p rowrc mgtop30 mgbt10'>
                    <button className='bingobtn10 font16 wt600 color20 btncur' onClick={() => this.props.handleNextStep(9)}>수정하기</button>
                  </div>
                </div>
              </div>
            </div>

            <div className='bingoodleft2 mglt30'>
              <div className='bingoodbox1 colcc'>
                <div className='bingoodin2 coltl'>
                  <label className='font20 wt500 color20'>빙고팡팡 주문 유형</label>

                  <div className='bingodescbox9 colevl mgtop10'>
                    <label className='font15 wt500 colorb3'>빙고 광고 유형</label>
                    <label className='font16 wt400 color31'>{this.props.item ? getBingoGameTypeName(this.props.item.gt) : "-"}</label>
                  </div>

                </div>
              </div>

              <div className='bingoodbox1 colcc mgtop10'>
                <div className='bingoodin2 coltl'>
                  <label className='font20 wt500 color20'>광고 생성 정보</label>

                  <div className='bingodescbox9 colevl mgtop10'>
                    <label className='font15 wt500 colorb3'>광고 타겟</label>
                    <label className='font16 wt400 color31'>{this.props.item ? getBingoTargetInfo(this.props.item) : "-"}</label>
                  </div>

                  <div className='bingodescbox9 colevl mgtop10'>
                    <label className='font15 wt500 colorb3'>광고 시작 희망 일시</label>
                    <label className='font16 wt400 color31'>{this.props.item && this.props.item.rdt > 0 ? getBingoRequestDate(this.props.item.rdt) : "-"}</label>
                  </div>

                  <div className='bingodescbox9 colevl mgtop10'>
                    <label className='font15 wt500 colorb3'>담장자 연락처</label>
                    <label className='font16 wt400 color31'>{this.props.item && this.props.item.userInfo ? `${this.props.item.userInfo.un} ${GetFormattedPhone2(this.props.item.userInfo.uph)}` : '-'}</label>
                  </div>

                  <div className='bingodescbox9 colevl mgtop10'>
                    <label className='font15 wt500 colorb3'>이메일</label>
                    <label className='font16 wt400 color31'>{this.props.item && this.props.item.userInfo ? this.props.item.userInfo.em : '-'}</label>
                  </div>

                </div>
              </div>
              
              <div className='bingoodbox1 colcc mgtop10'>
                <div className='bingoodin2 coltl'>
                  <label className='font20 wt500 color20'>결제 정보</label>

                  <div className='bingodescbox9 colevl mgtop10'>
                    <label className='font15 wt500 colorb3'>결제 유형</label>
                    <label className='font16 wt400 color31'>{this.props.item ? getBingoPurchaseMode(this.props.item.pm) : "-"}</label>
                  </div>

                  <div className='bingodescbox9 colevl mgtop10'>
                    <label className='font15 wt500 colorb3'>결제 금액</label>
                    <label className='font16 wt400 color31'>{this.props.item ? `${GetFormattedNum(this.props.item.pamt)}원` : "-"}</label>
                  </div>

                </div>
              </div>

              <div className='bingoodbox2 colcc mgtop10'>
                <div className='bingoodin2 coltl'>
                  
                  <div className='wd100p rowlc btncur' onClick={() => this.setTermCheck(!this.state.termCheck)}>
                    <img src={GetImgUrl(this.state.termCheck ? "bingo/sel.png" : "bingo/sel2.png")} alt="동의" className='mgrt5'/>
                    <label className='font16 wt500 color31 btncur'>유의사항을 확인한 후 동의하시면 체크해 주세요.</label>
                  </div>

                  <div className='bingorttextbox rowlc lht23 mglt25'>
                    <label className='font15 wt400 color31'>·</label>
                    <label className='font15 wt400 color31'>결제 후 광고 게시가 완료되면 환불이 불가능합니다.</label>
                  </div>

                  <div className='bingorttextbox rowlc lht23 mglt25'>
                    <label className='font15 wt400 color31'>·</label>
                    <label className='font15 wt400 color31'>빙고팡팡 생성 후 심볼 제작 완료 및 광고 승인이 완료되면 빙고팡팡 노출이 시작됩니다.</label>
                  </div>

                  <div className='bingorttextbox rowlc lht23 mglt25'>
                    <label className='font15 wt400 color31'>·</label>
                    <label className='font15 wt400 color31'>기한 내 소재가 제출되지 않거나 승인 조건을 만족하지 못하면 광고가 자동으로 취소됩니다.</label>
                  </div>

                  <div className='bingorttextbox rowlc lht23 mglt25'>
                    <label className='font15 wt400 color31'>·</label>
                    <label className='font15 wt400 color31'>광고 진행 도중 오류가 발생할 경우, 관리자에 의해 즉시 광고가 중단될 수 있습니다.</label>
                  </div>

                  <div className='bingorttextbox rowlc lht23 mglt25'>
                    <label className='font15 wt400 color31'>·</label>
                    <label className='font15 wt400 color31'>광고 진행 도중 기재된 내용이 제출된 내용과 상이할 경우 관리자에 의해 내용이 임의로 변경될 수 있습니다.</label>
                  </div>

                  <div className='bingorttextbox rowlc lht23 mglt25'>
                    <label className='font15 wt400 color31'>·</label>
                    <label className='font15 wt400 color31'>세금계산서는 요청 시 1영업일 이내에 발행됩니다.</label>
                  </div>
                </div>
              </div>

              <button className='bingobtn11 font16 wt600 colorw btncur mgtop10' onClick={() => {
                  if (!this.state.termCheck)
                  {
                    alert('유의사항에 동의해주세요.');
                    return;
                  }
                  this.props.handleNextStep(5);
                }}>등록하기</button>
            </div>
          </div>
          
        </div>
        <FooterBar handleMenu={this.handleMenu}/>
      </div>
    );
  }
}

export default BingoStep3;