import React, { Fragment } from 'react';
import './Bingo.css';
import '../../App.css';
import { GetImgUrl, LOG_ENABLE } from '../../URL/AppUrl';
import {  checkVideoBingo, getShortedPathName, getValidFileName, IsValidS, IsValidV, UploadFile, } from '../../Util/Util';
import Loading from '../Loading/Loading';
import FooterBar from '../Footer/Footer';
import { BingoStoreInstance } from '../../Stores/BingoStore';


//빙고 상세등록 2단계
class BingoStep2 extends React.Component
{
  state = {
    loading: false,

    bingoDesign: 0, //A형(0), B형(1)
    bingoName: '',
    mainBannerName: '',
    mainBannerFile: null,
    symbolName: '',
    symbolFile: null,
    bannerName: '',
    bannerFile: null,
    videoName: '',
    videoFile: null,

    gift: 0, //진행안함(0), 진행(1)
    giftCount: '', //경품 제공수

    videoOutsourcing: false, //영상 외주 요청

    selectedHelp: 0, //오른쪽 영역 선택된 도움말
  };

  saveData = null;
  changed = false; //내용변경이 있는가?
  mbChanged = false; //메인배너 바뀌었나?
  sbChanged = false; //심볼바뀌었나?
  bnChanged = false; //게임배너바뀌었나?
  vdChanged = false; //비디오바뀌었나?
  lastUploadType = 0; //메인배너(0), 심볼(1), 배너(2), 비디오(3)

  setLoading = (v) =>
  {
    this.setState((prevState) => ({...prevState, loading : v}));
  }

  //상품분류 설정
  setBingoDesign = (v) =>
  {
    if (v !== this.state.bingoDesign)
      this.changed = true;

    this.setState((prevState) => ({...prevState, bingoDesign: v}));
  }

  setBingoName = (v) =>
  {
    if (v !== this.state.bingoName)
      this.changed = true;

    this.setState((prevState) => ({...prevState, bingoName: v}));
  }

  setMainBanner = (name, file) =>
  {
    if (name !== this.state.mainBannerName || file !== this.state.mainBannerFile)
    {
      this.changed = true;
      this.mbChanged = true; //메인배너 바뀌었나?
    }

    this.setState((prevState) => ({...prevState, mainBannerName: name, mainBannerFile: file}));
  }

  setSymbol = (name, file) =>
  {
    if (name !== this.state.symbolName || file !== this.state.symbolFile)
    {
      this.changed = true;
      this.sbChanged = true; //심볼바뀌었나?
    }

    this.setState((prevState) => ({...prevState, symbolName: name, symbolFile: file}));
  }

  setBanner = (name, file) =>
  {
    if (name !== this.state.bannerName || file !== this.state.bannerFile)
    {
      this.changed = true;
      this.bnChanged = true; //게임배너바뀌었나?
    }

    this.setState((prevState) => ({...prevState, bannerName: name, bannerFile: file}));
  }

  setVideo = (name, file) =>
  {
    if (name !== this.state.videoName || file !== this.state.videoFile)
    {
      this.changed = true;
      this.vdChanged = true; //비디오바뀌었나?
    }

    this.setState((prevState) => ({...prevState, videoName: name, videoFile: file}));
  }

  setGiftMode = (v) =>
  {
    if (v !== this.state.gift)
      this.changed = true;

    this.setState((prevState) => ({...prevState, gift: v}));
  }

  setGiftCount = (v) =>
  {
    if (v !== this.state.giftCount)
      this.changed = true;

    this.setState((prevState) => ({...prevState, giftCount: v}));
  }
 
  setVideOutsourcing = (v) =>
  {
    if (v !== this.state.videoOutsourcing)
      this.changed = true;

    this.setState((prevState) => ({...prevState, videoOutsourcing: v}));
  }

  setSelectedHelp = (v) =>
  {
    this.setState((prevState) => ({...prevState, selectedHelp: v}));
  }

  componentDidMount() {
    if (LOG_ENABLE)
      console.log(`BingoStep2.componentDidMount`);

    if (IsValidV(this.props.step2))
    {
      this.changed = !this.props.step2.saved;
      
      if (this.changed)
      {
        if (this.props.step2.mainBannerFile)
          this.mbChanged = true;

        if (this.props.step2.symbolFile)
          this.sbChanged = true;

        if (this.props.step2.bannerFile)
          this.bnChanged = true;

        if (this.props.step2.videoFile)
          this.vdChanged = true;
      }

      let oldChanged = this.changed;

      this.saveData = null;
      this.setBingoName(IsValidS(this.props.step2.bingoName) && this.props.step2.bingoName !== "n" ? this.props.step2.bingoName : "");
      this.setBingoDesign(this.props.step2.bingoDesign);

      this.setMainBanner(this.props.step2.mainBannerName, this.props.step2.mainBannerFile);
      this.setSymbol(this.props.step2.symbolName, this.props.step2.symbolFile);
      this.setBanner(this.props.step2.bannerName, this.props.step2.bannerFile);
      this.setVideo(this.props.step2.videoName, this.props.step2.videoFile);
      this.setGiftMode(this.props.step2.gift);
      this.setGiftCount(this.props.step2.giftCount > 0 ? this.props.step2.giftCount.toString() : '');
      this.setVideOutsourcing(this.props.step2.vos);

      this.changed = oldChanged;
    }

    if (LOG_ENABLE)
      console.log(`chg=${this.changed}, mb=${this.mbChanged}, sb=${this.sbChanged}, bb=${this.bnChanged}, vd=${this.vdChanged}`);
  }

  componentWillUnmount = () =>
  {
    if (LOG_ENABLE)
      console.log(`BingoStep2.componentWillUnmount`);
  }

  //다음 버튼 눌림
  handleNext = (forSave) =>
  {
    if (this.state.loading)
      return;

    if (forSave)
    {
      if (!this.changed) //저장할 변경사항이 없음
      {
        //alert(`수정된 항목이 없습니다.`);
        this.props.handleNextStep(8, null);
        return;
      }
     
      //변경사항을 저장한다.
      this.saveData = {
        bn: this.state.bingoName,
        dn: this.state.bingoDesign,

        mb: this.state.mainBannerName,
        sb: this.state.symbolName,
        bb: this.state.bannerName,
        vd: this.state.videoName,

        mbFile: this.state.mainBannerFile,
        sbFile: this.state.symbolFile,
        bnFile: this.state.bannerFile,
        vdFile: this.state.videoFile,

        gc: 0,
        vos: this.state.videoOutsourcing ? 1 : 0, //영상 제작 아웃소싱여부
      };

      if (this.state.gift === 1)
      {
        if (!IsValidS(this.state.giftCount) || isNaN(this.state.giftCount))
        {
          alert(`경품 지급 개수를 5 ~ 20개 이내로 입력해 주세요.`);
          return;
        }

        const giftCount = parseInt(this.state.giftCount);

        if (giftCount < 5 || giftCount > 20)
        {
          alert(`경품 지급 개수를 5 ~ 20개 이내로 입력해 주세요.`);
          return;
        }

        this.saveData.gc = giftCount;
      }

      if (!IsValidS(this.saveData.bn))
        this.saveData.bn = "n";

      if (!IsValidS(this.saveData.mb))
        this.saveData.mb = "n";
      if (!IsValidS(this.saveData.sb))
        this.saveData.sb = "n";
      if (!IsValidS(this.saveData.bb))
        this.saveData.bb = "n";
      if (!IsValidS(this.saveData.vd))
        this.saveData.vd = "n";

      this.setLoading(true);
      this.uploadFiles();
    }
    else
    {
      const data = {
        saved: !this.changed,
        bingoName: this.state.bingoName,
        bingoDesign: this.state.bingoDesign,

        mainBannerName: this.state.mainBannerName,
        mainBannerFile: this.state.mainBannerFile,
        
        symbolFile: this.state.symbolFile,
        symbolName: this.state.symbolName,
        
        bannerFile: this.state.bannerFile,
        bannerName: this.state.bannerName,

        videoFile: this.state.videoFile,
        videoName: this.state.videoName,

        gift: this.state.gift,
        giftCount: 0,
        vos: this.state.videoOutsourcing ? 1 : 0, //영상 제작 아웃소싱여부
      };

      if (this.state.gift === 1)
      {
        if (!IsValidS(this.state.giftCount) || isNaN(this.state.giftCount))
        {
          alert(`경품 지급 개수를 5 ~ 20개 이내로 입력해 주세요.`);
          return;
        }

        const giftCount = parseInt(this.state.giftCount);

        if (giftCount < 5 || giftCount > 20)
        {
          alert(`경품 지급 개수를 5 ~ 20개 이내로 입력해 주세요.`);
          return;
        }

        data.giftCount = giftCount;
      }

      this.props.handleNextStep(4, data);
    }
    
  }

  //변경사항이 있는 파일부터 하나씩 차례로 업로드를 수행함
  uploadFiles = () =>
  {
    if (this.mbChanged && this.saveData.mbFile) //메인배너가 바뀌었다면
    {
      if (LOG_ENABLE)
        console.log(`upload main banner`);

      this.lastUploadType = 0; //메인배너(0), 심볼(1), 배너(2), 비디오(3)
      UploadFile(11, this.saveData.mbFile, this.onUploadDone);
      return;
    }
    else if (this.sbChanged && this.saveData.sbFile) //심볼파일바뀌었다면
    {
      if (LOG_ENABLE)
        console.log(`upload symbol`);

      this.lastUploadType = 1; //메인배너(0), 심볼(1), 배너(2), 비디오(3)
      UploadFile(11, this.saveData.sbFile, this.onUploadDone);
      return;
    }
    else if (this.bnChanged && this.saveData.bnFile) //심볼파일바뀌었다면
    {
      if (LOG_ENABLE)
        console.log(`upload banner`);

      this.lastUploadType = 2; //메인배너(0), 심볼(1), 배너(2), 비디오(3)
      UploadFile(11, this.saveData.bnFile, this.onUploadDone);
      return;
    }
    else if (this.vdChanged && this.saveData.vdFile) //심볼파일바뀌었다면
    {
      if (LOG_ENABLE)
        console.log(`upload video`);

      this.lastUploadType = 3; //메인배너(0), 심볼(1), 배너(2), 비디오(3)
      UploadFile(11, this.saveData.vdFile, this.onUploadDone);
      return;
    }
    else //업로드할게 없다면, 내용을 갱신해준다.
    {
      BingoStoreInstance.editStep2(this.props.oidx, 
        this.saveData.dn, //다지인타입
        this.saveData.bn, //게임타이틀
        this.saveData.mb, //메인배너
        this.saveData.sb,  //심볼참조이미지
        this.saveData.bb, //게임배너
        this.saveData.vd, //첨부동영상
        this.saveData.gc,  //경품개수
        this.saveData.vos, //영상외주신청여부
        this.onSaveDone);
    }
  }

  onSaveDone = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert(`변경 사항 저장에 실패 했습니다.\n오류코드 ${resultCode}`);

      if (IsValidV(this.state.mainBannerFile))
        this.mbChanged = true;

      if (IsValidV(this.state.symbolFile))
        this.sbChanged = true;

      if (IsValidV(this.state.bannerFile))
        this.bnChanged = true;

      if (IsValidV(this.state.videoFile))
        this.vdChanged = true;
      
      return;
    }

    //alert(`변경사항이 저장되었습니다.`);

    this.changed = false;
    this.mbChanged = false;
    this.sbChanged = false;
    this.bnChanged = false;
    this.vdChanged = false;

    this.props.item.dn = this.saveData.dn;
    this.props.item.gti = this.saveData.bn;
    this.props.item.gfc = this.saveData.gc;

    if (!this.props.item.imgInfo)
      this.props.item.imgInfo = {};

    this.props.item.imgInfo.bimg = getValidFileName(this.saveData.mb, this.props.item.imgInfo.bimg, "n");
    this.props.item.imgInfo.simg = getValidFileName(this.saveData.sb, this.props.item.imgInfo.simg, "n");
    this.props.item.imgInfo.gimg = getValidFileName(this.saveData.bb, this.props.item.imgInfo.gimg, "n");
    this.props.item.imgInfo.vdf = getValidFileName(this.saveData.vd, this.props.item.imgInfo.vdf, "n");
    this.props.item.imgInfo.vos = this.saveData.vos;

    const data = {
      saved: true,
      bingoName: this.state.bingoName,
      bingoDesign: this.state.bingoDesign,

      mainBannerName: this.state.mainBannerName,
      mainBannerFile: this.state.mainBannerFile,
      
      symbolFile: this.state.symbolFile,
      symbolName: this.state.symbolName,
      
      bannerFile: this.state.bannerFile,
      bannerName: this.state.bannerName,

      videoFile: this.state.videoFile,
      videoName: this.state.videoName,

      gift: this.state.gift,
      giftCount: 0,
      vos: this.state.videoOutsourcing ? 1 : 0, //영상 제작 아웃소싱여부
    };

    this.props.handleNextStep(40, data);
  }
  
  onUploadDone = (success, oldName, newName) =>
  {
    if (!success)
    {
      this.setLoading(false);
      alert(`파일 업로드 중 오류가 발생했습니다.\n임시 저장에 실패 했습니다.`);
      return;
    }

    if (LOG_ENABLE)
      console.log(`onUploadDone : ${oldName}/${newName}`);

    switch(this.lastUploadType)
    {
      case 0:
        this.mbChanged = false;
        this.saveData.mb = newName;
        break;
      case 1:
        this.sbChanged = false;
        this.saveData.sb = newName;
        break;
      case 2:
        this.bnChanged = false;
        this.saveData.bb = newName;
        break;
      case 3:
        this.vdChanged = false;
        this.saveData.vd = newName;
        break;
      default:
        break;
    }

    this.uploadFiles();
  }

  handleSelectFile = (fileType) =>
  {
    let fileId = null;

    switch(fileType)
    {
      case 0: //메인배너
        fileId = "idmain";
        break;
      case 1: //심벌
        fileId = "idsymbol";
        break;
      case 2: //광고배너
        fileId = "idbanner";
        break;
      case 3: //비디오
        fileId = "idvideo";
        break;
      default:
        return;
    }

    const input = document.getElementById(fileId);

    if (input)
      input.click();
  }

  handleSymbolDesc = () =>
  {
    alert(`준비중입니다.`);
  }
  
  checkImageSize = (file, width, height, callback) =>
  {
    try
    {
      // 파일을 읽기 위한 FileReader 객체를 생성합니다.
      const reader = new FileReader();
          
      // 파일 로드가 완료되면 실행될 콜백 함수를 정의합니다.
      reader.onload = function(event) {
        // 이미지 객체를 생성합니다.
        var image = new Image();

        // 이미지 객체의 로드가 완료되면 실행될 콜백 함수를 정의합니다.
        image.onload = function() {
          // 이미지 객체의 너비와 높이를 가져옵니다.
          var imageWidth = this.width;
          var imageHeight = this.height;

          // 이미지의 크기를 체크합니다.
          if (imageWidth !== width || imageHeight !== height) {
            callback(false, file);
          }
          else
            callback(true, file);
        };

        // 이미지 객체에 파일 데이터를 설정하고 로드합니다.
        image.src = event.target.result;
      };

      // FileReader 객체에 파일 데이터를 설정하고 로드합니다.
      reader.readAsDataURL(file);
    }
    catch(e)
    {
      callback(false, file);
    }
  }

  render()
  {
    return (
      <div className='bingocont colel' style={{width: this.props.contWidth, height: this.props.height}}>
        {
          this.state.loading ? (<Loading/>) : null
        }
        <div className='bingocont2 coltl' style={{width: this.props.contWidth - 40 - 50, height: this.props.height - 100}}>
          <label className='font20 wt600 color31 mgtop20'>빙고팡팡 광고</label>

          <div className='wd100p rowlt mgtop20 mgbt50'>
            <div className='bingoodleft'>
              <div className='bingoodbox1 colcc'>
                <div className='bingoodin coltl'>
                  <label className='font20 wt500 color31'>빙고를 생성하기 위해 정보를 작성해 주세요</label>

                  <label className='font15 wt500 color31 mgtop10'>빙고 디자인</label>

                  <div className='rowcc mgtop10'>
                    <div className={this.state.bingoDesign === 0 ? 'bingoselbox1 rowlc btncur mgrt5' : 'bingoselbox2 rowlc btncur mgrt5'} onClick={() => this.setBingoDesign(0)}>
                      <img src={GetImgUrl(this.state.bingoDesign === 0 ? 'bingo/sel.png' : 'bingo/sel2.png')} alt="선택" className='mglt5'/>
                      <label className={this.state.bingoDesign === 0 ? 'font15 wt500 color20 mglt5 btncur' : 'font15 wt500 color31 mglt5 btncur'}>자연</label>
                    </div>

                    {/*<div className={this.state.bingoDesign === 1 ? 'bingoselbox1 rowlc btncur' : 'bingoselbox2 rowlc btncur'} onClick={() => this.setBingoDesign(1)}>
                      <img src={GetImgUrl(this.state.bingoDesign === 1 ? 'bingo/sel.png' : 'bingo/sel2.png')} alt="선택" className='mglt5'/>
                      <label className={this.state.bingoDesign === 1 ? 'font15 wt500 color20 mglt5 btncur' : 'font15 wt500 color31 mglt5 btncur'}>B형</label>
                    </div>*/}
                  </div>

                  <label className='font15 wt500 color31 mgtop20'>빙고 이름</label>

                  <input type='text' value={this.state.bingoName} onChange={e => this.setBingoName(e.target.value)} className='bingoodinp font16 wt400 color31 mgtop5' placeholder='띄어쓰기 포함 최대 17자까지 입력 가능합니다' maxLength={17}/>

                  <label className='font15 wt500 color31 mgtop20'>메인 배너 이미지</label>

                  <div className='wd100p rowsc mgtop5'>
                    <div className='bingofilebox1 rowlc'>
                      <label className='font15 wt500 color31 mglt10'>{getShortedPathName(this.state.mainBannerName, 30)}</label>
                    </div>

                    <button className='bingobtn8 font16 wt600 color20 btncur' onClick={() => this.handleSelectFile(0)}>첨부하기</button>
                  </div>

                  <label className='font15 wt400 colorb3'>※ 948 X 330 사이즈 PNG, JPEG포맷의 파일만 업로드 가능합니다.</label>

                  <label className='font15 wt500 color31 mgtop20'>심볼 이미지</label>

                  <div className='wd100p rowsc mgtop5'>
                    <div className='bingofilebox1 rowlc'>
                      <label className='font15 wt500 color31 mglt10'>{getShortedPathName(this.state.symbolName, 30)}</label>
                    </div>

                    <button className='bingobtn8 font16 wt600 color20 btncur' onClick={() => this.handleSelectFile(1)}>첨부하기</button>
                  </div>

                  <button className='bingobtn9 font15 wt500 color31 btncur mgtop10' onClick={this.handleSymbolDesc}>심볼 양식 작성 양식 다운로드</button>

                  <div className='bingodescbox4 coltl mgtop10'>
                    <label className='font15 wt400 color31'>심볼 이미지는 실비로 제작하고 있어요.<br/>"심볼 양식 작성 양식"파일을 다운로드한 후 양식을 작성해서 업로드 하면 빠른 제작이 가능해요<br/>궁금한 부분이 있다면 우측 “빙고 디자인 작업가이드 안내 및 TIP”을 확인하거나 카카오톡 문의하기를 통해 문의하면 알려드릴게요</label>
                  </div>

                  <label className='font15 wt500 color31 mgtop20'>광고 배너 이미지</label>

                  <div className='wd100p rowsc mgtop5'>
                    <div className='bingofilebox1 rowlc'>
                      <label className='font15 wt500 color31 mglt10'>{getShortedPathName(this.state.bannerName, 30)}</label>
                    </div>

                    <button className='bingobtn8 font16 wt600 color20 btncur' onClick={() => this.handleSelectFile(2)}>첨부하기</button>
                  </div>
                  <label className='font15 wt400 colorb3'>※ 1284 X 150 사이즈 PNG, JPEG포맷의 파일만 업로드 가능합니다.</label>
                  <label className='font15 wt400 colorb3'>※ 광고 배너 이미지 좌우측 102픽셀은 휴대전화 기종에 따라서 잘려 보일수 있으니 주의해 주세요.</label>

                  { checkVideoBingo(this.props.item.gt) ? (
                    <Fragment>
                      <label className='font15 wt500 color31 mgtop20'>광고 영상</label>

                      <div className='wd100p rowsc mgtop5'>
                        <div className='bingofilebox1 rowlc'>
                          <label className='font15 wt500 color31 mglt10'>{this.state.videoName}</label>
                        </div>

                        <button className='bingobtn8 font16 wt600 color20 btncur' onClick={() => this.handleSelectFile(3)}>첨부하기</button>
                      </div>

                      <div className={this.state.videoOutsourcing ? 'bpvdosbg1 rowlc btncur mgtop10' : 'bpvdosbg2 rowlc btncur mgtop10'} onClick={() => this.setVideOutsourcing(!this.state.videoOutsourcing)}>
                        <img src={GetImgUrl(this.state.videoOutsourcing ? 'bingo/sel.png' : 'bingo/sel2.png')} alt="선택" className='mglt5'/>
                        <label className={this.state.videoOutsourcing ? 'font15 wt500 color20 mglt5 btncur' : 'font15 wt500 color31 mglt5 btncur'}>영상이 없어서 제작대행(유료) 맡기고 싶어요</label>
                      </div>
                      <label className='font15 wt400 colorb3 mgtop5'>※ 9:16비율, 50MB이하의 mp4포맷의 파일만 업로드 가능합니다.</label>
                    </Fragment>
                  ) : null}
                  

                  <label className='font15 wt500 color31 mgtop20'>경품 지급</label>

                  <div className='wd100p rowsc mgtop5'>
                    <div className={this.state.gift === 1 ? 'bingoselbox3 rowcc btncur mgrt5' : 'bingoselbox4 rowcc btncur mgrt5'} onClick={() => this.setGiftMode(1)}>
                      <label className={this.state.gift === 1 ? 'font15 wt500 color20 mglt5 btncur' : 'font15 wt500 color31 mglt5 btncur'}>진행</label>
                    </div>

                    <div className={this.state.gift === 0 ? 'bingoselbox3 rowcc btncur' : 'bingoselbox4 rowcc btncur'} onClick={() => this.setGiftMode(0)}>
                      <label className={this.state.gift === 0 ? 'font15 wt500 color20 mglt5 btncur' : 'font15 wt500 color31 mglt5 btncur'}>진행안함</label>
                    </div>
                  </div>

                  <div className='bingodescbox5 rowcc mgtop10'>
                    <label className='font16 wt400 color31'>경품으로 상품을 제공하게 될 경우 참여도와 관심이 증대돼요</label>
                  </div>

                  {
                    this.state.gift === 1 ? (
                      <input type='number' value={this.state.giftCount} onChange={e => this.setGiftCount(e.target.value)} className='bingoodinp font16 wt400 color31 mgtop20' placeholder='경품 지급 개수를 5개에서 20개 이내로 입력해 주세요.' maxLength={5}/>
                    ) : null
                  }

                  <div className='wd100p rowsc mgtop30'>
                    <button className='bingobtn6 font16 wt600 colorw btncur' onClick={() => this.handleNext(true)}>저장 후 이전</button>
                    <button className='bingobtn7 font16 wt600 colorw btncur' onClick={() => this.handleNext(false)}>다음</button>
                  </div>
                </div>
              </div>
              
            </div>

            <div className='bingoodright colcc mglt30'>
              <div className='bingoodin coltl'>
                <label className='font20 wt500 color31'>빙고 디자인 작업가이드 안내 및 TIP</label>

                <div className='bingodescbox6 mgtop15'>

                </div>

                <div className={this.state.selectedHelp === 0 ? 'bingodescbox7 rowsc btncur mgtop30' : 'bingodescbox8 rowsc btncur mgtop30'} onClick={() => this.setSelectedHelp(0)}>
                  <label className='font16 wt400 color31 btncur'>메인 배너 이미지가 어디에 노출이 되나요?</label>
                  <img src={GetImgUrl("bingo/right.png")} alt="확인"/>
                </div>

                <div className={this.state.selectedHelp === 1 ? 'bingodescbox7 rowsc btncur mgtop10' : 'bingodescbox8 rowsc btncur mgtop10'} onClick={() => this.setSelectedHelp(1)}>
                  <label className='font16 wt400 color31 btncur'>일반형 심볼과 사진형 심볼이 무엇인지 알려주세요.</label>
                  <img src={GetImgUrl("bingo/right.png")} alt="확인"/>
                </div>

                <div className={this.state.selectedHelp === 2 ? 'bingodescbox7 rowsc btncur mgtop10' : 'bingodescbox8 rowsc btncur mgtop10'} onClick={() => this.setSelectedHelp(2)}>
                  <label className='font16 wt400 color31 btncur'>광고 배너 이미지가 어디에 노출이 되나요?</label>
                  <img src={GetImgUrl("bingo/right.png")} alt="확인"/>
                </div>

                <div className={this.state.selectedHelp === 3 ? 'bingodescbox7 rowsc btncur mgtop10' : 'bingodescbox8 rowsc btncur mgtop10'} onClick={() => this.setSelectedHelp(3)}>
                  <label className='font16 wt400 color31 btncur'>광고 영상이 없는데 꼭 업로드해야 할까요?</label>
                  <img src={GetImgUrl("bingo/right.png")} alt="확인"/>
                </div>

              </div>
            </div>
          </div>
          
          <input type="file" id="idmain" className="bingohiddenfile" accept="image/png, image/jpeg"
            onChange={(e) => {

              if (!IsValidV(e) || !IsValidV(e.target) || !IsValidS(e.target.files))
                return;

              this.checkImageSize(e.target.files[0], 948, 330, (success, file) => {

                if (!success)
                {
                  alert(`배너 이미지 크기가 올바르지 않습니다.\n948 X 330 사이즈 PNG, JPEG파일만 업로드 가능합니다.`);
                }
                else
                  this.setMainBanner(e.target.files[0].name, e.target.files[0]);
              });
          }}/>

          <input type="file" id="idsymbol" className="bingohiddenfile" accept="image/png, image/jpeg"
            onChange={(e) => {

              if (!IsValidV(e) || !IsValidV(e.target) || !IsValidS(e.target.files))
                return;

              if (e.target.files[0].size > (1024 * 1024 * 5))
              {
                alert(`심볼 이미지 용량이 초과되었습니다.\n5MB이하의 PNG, JPEG파일만 업로드 가능합니다.`);
              }
              else
                this.setSymbol(e.target.files[0].name, e.target.files[0]);
          }}/>

          <input type="file" id="idbanner" className="bingohiddenfile" accept="image/png, image/jpeg"
            onChange={(e) => {

              if (!IsValidV(e) || !IsValidV(e.target) || !IsValidS(e.target.files))
                return;

              this.checkImageSize(e.target.files[0], 1284, 150, (success, file) => {

                if (!success)
                {
                  alert(`배너 이미지 크기가 올바르지 않습니다.\n1284 X 150 사이즈 PNG, JPEG파일만 업로드 가능합니다.`);
                }
                else
                  this.setBanner(e.target.files[0].name, e.target.files[0]);
              });
              
          }}/>

          <input type="file" id="idvideo" className="bingohiddenfile" accept="video/mp4"
            onChange={(e) => {

              if (!IsValidV(e) || !IsValidV(e.target) || !IsValidS(e.target.files))
                return;

              if (e.target.files[0].size > (1024 * 1024 * 50))
              {
                alert(`광고 영상 용량이 초과되었습니다.\n9:16비율 50MB이하의 mp4 파일만 업로드 가능합니다.`);
              }
              else
                this.setVideo(e.target.files[0].name, e.target.files[0]);
          }}/>

        </div>
        <FooterBar handleMenu={this.handleMenu}/>
      </div>
    );
  }
}

export default BingoStep2;