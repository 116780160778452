import './Purchase.css';
import '../../App.css';
import { GetImgUrl, mobileOn } from '../../URL/AppUrl';
import { GetFormattedNum, getVirtualBankLimitTime } from '../../Util/Util';

const PurchaseResult = (props) =>
{
  /*if (mobileOn)
  {
    return (
      <div className='purretbg_mb flexcoltc'>
        <div className='fullwidth flexrowlc margintop25'>
          <label className='font18b'>충전하기</label>
        </div>

        <img src={GetImgUrl("charge/mb/charge.png")} alt='충전' className='margintop20 img6060'/>
  
        
        <label className='font20 wt500 color31 margintop15'>신청 완료</label>
        <label className='font16 wt400 color31 margintop5'>이용해 주셔서 감사합니다.</label>
  
        <div className='purretline margintop30'></div>
  
        <div className='fullwidth flexcoltl margintop10'>
          <label className='font12b'>입금계좌 정보</label>
  
          <div className='flexrowcc margintop15'>
            <label className='font12202 marginright10'>예금주명</label>
            <label className='font12b'>{props.orderInfo.bi.bun}</label>
          </div>
  
          <div className='flexrowcc margintop5'>
            <label className='font12202 marginright10'>거래은행</label>
            <label className='font12b'>{props.orderInfo.bi.bn}</label>
          </div>
  
          <div className='flexrowcc margintop5'>
            <label className='font12202 marginright10'>계좌번호</label>
            <label className='font12b'>{props.orderInfo.bi.ban}</label>
          </div>
  
          <div className='purretline margintop10'></div>
  
          {
            props.orderInfo.pmd === 0 ? (
              <div className='flexrowcc margintop10'>
                <label className='font12202 marginright10'>입금자명</label>
                <label className='font12b'>{props.orderInfo.bi.un}</label>
              </div>
            ) : null
          }
          
  
          <div className='flexrowcc margintop5'>
            <label className='font12202 marginright10'>입금금액</label>
            <label className='font12b'>{`${GetFormattedNum(props.orderInfo.tc)}원 (VAT포함)`}</label>
          </div>
  
          <div className='flexrowcc margintop5'>
            <label className='font12202 marginright10'>입금기한</label>
            <label className='font12b'>{`${getVirtualBankLimitTime(props.orderInfo.rd)} 23:59까지`}</label>
          </div>
  
          <div className='purretline margintop10'></div>
  
          <label className='font14b_2 margintop15 lineheight20'>·결제금액이 일치해야 무통장입금처리가 완료됩니다.<br/>·무통장입금 완료 처리는 5분 이내 자동으로 잔액이 충전되며, 충전이 안됐을 경우 문의해 주세요.<br/>·신청 후 익일 23시 59분까지 입금하지 않을 경우 취소 처리됩니다.</label>
  
          <button className='purretbtn_mb btncur font14btn4 margintop40 marginbottom70' onClick={props.handleCloseResult}>확인</button>
        </div>
      </div>
    );
  }
  else*/
  {
    if (props.orderInfo.pmd === 1) //카드결제
    {
      return (
        <div className='purretbg flexcoltc'>
          <img src={GetImgUrl("charge/result.png")} alt='충전'/>
    
          <label className='font20 wt500 color31 margintop15'>결제 완료</label>
          <label className='font16 wt400 color31 margintop5'>이용해 주셔서 감사합니다.</label>
    
          <div className='purretline margintop30'></div>
    
          <div className='fullwidth flexcoltl margintop10'>
            <label className='font15 wt500 color31'>결제 정보</label>
    
            <div className='flexrowcc margintop5'>
              <label className='font16 wt400 color20 marginright10'>결제수단</label>
              <label className='font16 wt400 color31'>카드</label>
            </div>

            <div className='flexrowcc margintop5'>
              <label className='font16 wt400 color20 marginright10'>결제금액</label>
              <label className='font16 wt400 color31'>{`${GetFormattedNum(props.orderInfo ? props.orderInfo.tc: 0)}원 (VAT포함)`}</label>
            </div>
    
            <div className='flexrowcc margintop5'>
              <label className='font16 wt400 color20 marginright10'>충전금액</label>
              <label className='font16 wt400 color31'>{`${GetFormattedNum(props.orderInfo ? props.orderInfo.prc: 0)}P`}</label>
            </div>
    
            <div className='purretline margintop10'></div>
    
            <label className='font15 wt400 color31 lht23 margintop15'>·포인트 충전이 완료되었습니다.</label>
    
            <button className='purretbtn btncur font14 wt700 colorw margintop40 marginbottom150' onClick={props.handleCloseResult}>확인</button>
          </div>
        </div>
      );
    }
    else //가상계좌 또는 수동입금
    {
      return (
        <div className='purretbg flexcoltc'>
          <img src={GetImgUrl("charge/result.png")} alt='충전'/>
    
          <label className='font20 wt500 color31 margintop15'>신청 완료</label>
          <label className='font16 wt400 color31 margintop5'>이용해 주셔서 감사합니다.</label>
    
          <div className='purretline margintop30'></div>
    
          <div className='fullwidth flexcoltl margintop10'>
            <label className='font15 wt500 color31'>입금계좌 정보</label>
    
            <div className='flexrowcc mgtop5'>
              <label className='font16 wt400 color20 marginright10'>예금주명</label>
              <label className='font16 wt400 color31'>{props.orderInfo.bi.bun}</label>
            </div>
    
            <div className='flexrowcc margintop5'>
              <label className='font16 wt400 color20 marginright10'>거래은행</label>
              <label className='font16 wt400 color31'>{props.orderInfo.bi.bn}</label>
            </div>
    
            <div className='flexrowcc margintop5'>
              <label className='font16 wt400 color20 marginright10'>계좌번호</label>
              <label className='font16 wt400 color31'>{props.orderInfo.bi.ban}</label>
            </div>
    
            <div className='purretline margintop10'></div>
    
            {
              props.orderInfo.pmd === 0 ? (
                <div className='flexrowcc margintop10'>
                  <label className='font16 wt400 color20 marginright10'>입금자명</label>
                  <label className='font16 wt400 color31'>{props.orderInfo.bi.un}</label>
                </div>
              ) : null
            }
            
            <div className='flexrowcc margintop5'>
              <label className='font16 wt400 color20 marginright10'>입금금액</label>
              <label className='font16 wt400 color31'>{`${GetFormattedNum(props.orderInfo.tc)}원 (VAT포함)`}</label>
            </div>
    
            <div className='flexrowcc margintop5'>
              <label className='font16 wt400 color20 marginright10'>입금기한</label>
              <label className='font16 wt400 color31'>{`${getVirtualBankLimitTime(props.orderInfo.rd)} 23:59까지`}</label>
            </div>
    
            <div className='purretline margintop10'></div>
    
            <label className='font15 wt400 color31 lht23 margintop15'>·결제금액이 일치해야 무통장입금처리가 완료됩니다.<br/>·무통장입금 완료 처리는 5분 이내 자동으로 잔액이 충전되며, 충전이 안됐을 경우 문의해 주세요.<br/>·신청 후 익일 23시 59분까지 입금하지 않을 경우 취소 처리됩니다.</label>
    
            <button className='purretbtn btncur font14 wt700 colorw margintop40 marginbottom150' onClick={props.handleCloseResult}>확인</button>
          </div>
        </div>
      );
    }
  }
}

export default PurchaseResult;